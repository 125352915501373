/*
409df1
0082f7
site color
*/

/*
|----------------------------------------------------------------------
|       Body Main Css
|----------------------------------------------------------------------
*/

@font-face {
  font-family: "Light";
  src: url("../src/assets/fonts/Sofia-Pro-Light.woff");
}

@font-face {
  font-family: "Regular";
  src: url("../src/assets/fonts/Sofia-Pro-Regular.woff");
}

@font-face {
  font-family: "Medium";
  src: url("../src/assets/fonts/Sofia-Pro-Medium.woff");
}

@font-face {
  font-family: "SemiBold";
  src: url("../src/assets/fonts/Sofia-Pro-Semi-Bold.woff");
}

@font-face {
  font-family: "Bold";
  src: url("../src/assets/fonts/Sofia-Pro-Bold.woff");
}

.social-links {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.social-links a {
  background-color: var(--honey);
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-links a img {
  filter: brightness(0) invert(1);
  height: 25px;
  width: 25px;
}

.popup-modal-body {
  height: 100vh !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

}

.popup_modal {
  position: fixed;
  height: 240px;
  top: 40%;
  left: 42%;
  width: 350px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 512;
}

.cross-icon {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 15px;
}

.cross-icon .fi {
  cursor: pointer;
}

.modal-body {
  height: 100%;
  padding: 15px;
}

.share_link {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 10px 10px 0;
}

.modal-body input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-body button {
  padding: 13px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: var(--honey);
  color: white;
  cursor: pointer;
}

.light {
  font-family: "Light" !important;
}

.italic {
  font-family: "Italic" !important;
}

.regular {
  font-family: "Regular" !important;
}

.medium {
  font-family: "Medium" !important;
}

.semi {
  font-family: "SemiBold" !important;
}

.bold {
  font-family: "Bold" !important;
}

.price {
  font-family: "Bold";
}

:root {
  --gray: #474747;
  --honey: #b5936e;
  --black: #000;
  --chevron: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" fill="%23080a0f" viewBox="-155 247 300 300" style="enable-background:new -155 247 300 300;"><polygon points="78.6356201,306.8178101 -5.0166931,390.4367371 -88.6356277,306.8178101 -137, 355.1821899 -5.0166931,487.1821899 127,355.1821899 "/></svg>');
  --tick: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" fill="%23fff" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve"><polygon points="115.7434006,270.8831787 0,173.503006 21.3676376,148.106369 111.6297684,224.0477905 274.5331421,29.1168175 300,50.3998222"/></svg>');
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

.dropLangBtn {
  border: none !important;
  cursor: pointer;
  width: 80px !important;
}

body {
  position: relative;
  min-height: 100%;
  /* background-color: #f5f6f8; */
  background-color: #fff;
  color: var(--gray);
  font-size: 16px;
  font-family: "Regular";
  margin: 0;
  padding: 0;
  line-height: 1.6;
  word-break: break-word;
  transition: all ease 0.5s;
  overflow-x: hidden;
  counter-reset: my-sec-counter;
}

body {
  display: flex;
  flex-flow: column;
}

body #root {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

body.flow {
  overflow: hidden !important;
}

body main {
  flex: 1;
  padding-top: 72px;
  overflow: hidden;
}

body>main[index] {
  padding-top: 75;
}

::selection {
  background: #6e5650;
  color: #fff;
}

::-moz-selection {
  background: #6e5650;
  color: #fff;
}

body a {
  color: #080a0f;
  word-break: break-word;
}

body a,
body span {
  display: inline-block;
  text-decoration: none;
}

body a:hover {
  color: #6e5650;
}

body a:focus,
body a:hover {
  outline: none !important;
  text-decoration: none !important;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  display: inline-block;
}

ul li a {
  transition: all ease 0.5s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Regular";
  line-height: 1.2;
  margin: 0 0 10px;
}

p {
  margin: 0 0 15px;
}

p:nth-last-child(1) {
  margin: 0;
}

p>a {
  color: var(--honey);
}

p>a:hover {
  color: var(--honey);
  opacity: 0.6;
}

.jobs-left {
  color: var(--honey);
}

.relative {
  position: relative;
}

.tableDv {
  display: table;
  width: 100%;
  height: 100%;
}

.tableCell {
  display: table-cell;
  vertical-align: middle;
}

.toggle {
  position: absolute;
  top: 16px;
  right: 5px;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  transition: all ease 0.5s;
  z-index: 4;
}

.toggle span,
.toggle span:before,
.toggle span:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background: #080a0f;
  border-radius: 50px;
  transition: all ease 0.5s;
}

.toggle span {
  position: relative;
  display: block;
  width: 22px;
  margin: 8px auto;
}

.toggle span:before {
  top: -7px;
}

.toggle span:after {
  bottom: -7px;
}

.toggle.active span {
  width: 22px;
  background: transparent !important;
}

.toggle.active span:before,
.toggle.active span:after {
  top: 0;
}

.toggle.active span:before {
  transform: rotate(405deg);
}

.toggle.active span:after {
  transform: rotate(-45deg);
}

.blockquote {
  background: #fafafa;
  color: #151d23;
  padding: 10px 10px 10px 20px;
  border: 1px solid #f0f5f9;
  border-left: 4px solid #0071bd;
}

.heading {
  position: relative;
  margin-bottom: 25px;
}

.heading>em {
  color: #0071bd;
}

.sub_heading {
  color: #0071bd;
  font-size: 14px;
  font-family: "Medium";
  text-transform: uppercase;
}

p.pre {
  color: #8b9593;
  margin: -10px 0 30px;
}

.color {
  color: #0071bd !important;
}

.background {
  background: #0071bd !important;
}

/*
  |----------------------------------------------------------------------
  |       Body Main Button
  |----------------------------------------------------------------------
  */
.my-number-input::-webkit-inner-spin-button,
.my-number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select.txtBox.languageSwitch {
  color: black;
  position: relative;
}

.langSwitchDropDown {
  cursor: pointer;
  border: none !important;
}

.candidate-loged {
  pointer-events: none;
  background: #c2ad96 !important;
}

.title-text {
  transition: all ease 0.5s;
  display: none !important;
}

.webBtn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 46px;
  background: var(--honey);
  color: #fff;
  font-size: 14px;
  font-family: "Medium";
  text-align: center;
  padding: 0 30px;
  white-space: nowrap;
  border: 0;
  border-radius: 5px;
  outline: none !important;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
}

.cv-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 52px;
  background: var(--honey);
  color: #fff;
  font-size: 14px;
  font-family: "Medium";
  text-align: center;
  padding: 0 10px;
  white-space: nowrap;
  border: 0;
  border-radius: 5px;
  outline: none !important;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  margin-left: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
}

.webBtn:hover,
.blackblackBtn {
  background: #000000;
  color: #fff;
}

.smBtn {
  min-width: 40px;
  height: 36px;
  padding: 0 20px;
}

.mdBtn {
  min-width: 80px;
  height: 40px;
}

.lgBtn {
  height: 54px;
  font-size: 16px;
  padding: 0 40px;
}

.colorBtn {
  background: var(--honey);
}

.blankBtn {
  background: transparent;
  color: var(--honey);
  border: 1px solid var(--honey);
}

.blankBtn:hover {
  background: var(--honey);
  color: #fff;
}

.lightBtn {
  background: #eeccd1;
  color: var(--gray);
}

.lightBtn:hover {
  background: #cd6675;
  color: var(--gray);
}

.labelBtn {
  min-width: initial;
  height: auto;
  background: transparent;
  color: var(--gray);
  padding: 0;
  border: 0;
  box-shadow: none;
}

.labelBtn:hover {
  background: transparent;
  color: #080a0f;
}

.labelBtn>i {
  font-size: 100%;
}

.simpleBtn {
  background: #006fc0;
  color: #ffffff;
  /* border: 1px solid #b5bcc0; */
}

.simpleBtn:hover {
  background: #fff;
  color: #006fc0;
  /* border-color: #0071bd; */
}

.simpleBtn.icoBtn>img {
  filter: none;
}

.grayBtn {
  background: #fff;
  color: #8b9593;
  border: 1px solid #eee;
}

.grayBtn:hover {
  background: #fafafa;
  color: #8b9593;
}

.borderBtn {
  border: 1px solid #0071bd;
}

.arrowBtn {
  display: flex !important;
  width: 46px;
  min-width: 46px;
  padding: 0 5px;
}

.arrowBtn>i {
  font-weight: bold;
  margin: 0 !important;
}

.icoBtn>img {
  width: 18px;
  margin-right: 10px;
  filter: brightness(0) invert(1);
}

.icoBtn>img {
  width: 18px;
  margin-right: 10px;
  filter: brightness(0) invert(1);
}

.roundBtn {
  border-radius: 50px;
}

.blockBtn {
  width: 100%;
}

.blockBtn em {
  flex: 1;
  text-align: left;
}

.webBtn i {
  font-size: 140%;
  margin-right: 10px;
}

.webBtn.smBtn i {
  font-size: 120%;
}

.webBtn i.fi-arrow-right {
  font-weight: bold;
  margin-left: 10px;
  margin-right: 0;
}

.webBtn i.fi-arrow-left {
  font-weight: bold;
  margin-left: 0;
  margin-right: 10px;
}

.text-white {
  color: #fff;
}

.remember-btn {
  display: flex;
}

/*_____ webBtn spinner _____*/

.webBtn .spinner {
  width: auto;
  height: auto;
  background: inherit;
  border-radius: inherit;
  overflow: hidden;
}

.webBtn .spinner~i {
  visibility: hidden;
}

.webBtn .spinner,
.webBtn .spinner:before,
.webBtn .spinner:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.webBtn .spinner:before,
.webBtn .spinner:after {
  content: "";
  width: 18px;
  height: 18px;
  border: 3px solid;
  animation: spinner 0.5s linear infinite;
  border-radius: 75%;
}

.webBtn .spinner:before {
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.webBtn .spinner:after {
  border-top-color: transparent;
  border-left-color: transparent;
  opacity: 0.5;
}

.webBtn.smBtn .spinner:before,
.webBtn.smBtn .spinner:after {
  width: 14px;
  height: 14px;
}

.webBtn.lgBtn .spinner:before,
.webBtn.lgBtn .spinner:after {
  width: 20px;
  height: 20px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

*[disabled] {
  cursor: not-allowed !important;
}

a[disabled],
button[disabled] {
  opacity: 0.8;
}

.learnBtn {
  background: transparent;
  color: #0071bd;
  font-family: "SemiBold";
  padding: 0;
  text-transform: capitalize;
  border: 0;
}

.learnBtn i {
  position: relative;
  top: 3px;
  font-weight: bold;
  margin-left: 5px;
  /* transform: scale(-1); */
  transition: padding ease 0.5s;
}

.learnBtn:hover i {
  padding-left: 10px;
}

/*
  |----------------------------------------------------------------------
  |       Body Inner Css
  |----------------------------------------------------------------------
  */

h1 {
  font-size: 40px;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

img {
  width: 100%;
  display: block;
}

em {
  font-style: normal;
}

strong {
  font-family: "SemiBold";
  font-weight: normal;
}

input,
button,
select,
textarea {
  text-decoration: none !important;
  outline: none !important;
}

.ease,
body a,
body button,
body .webBtn {
  transition: all ease 0.5s;
}

.contain,
.contain-fluid {
  max-width: 1240px;
  padding: 0 15px;
  margin: auto;
  min-height: 1px;
  z-index: 3;
}

.contain-fluid {
  max-width: 1600px;
}

.contain:before,
.contain:after,
.contain-fluid:before,
.contain-fluid:after {
  content: "";
  display: table;
}

.contain:after,
.contain-fluid:after {
  clear: both;
}

section {
  position: relative;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.formRow>[class*="col-"],
.formRow>[class^="col-"] {
  padding: 10px;
}

.flexBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexDv {
  width: 100%;
}

.flexRow {
  margin: -10px;
}

.flexRow>.col {
  padding: 10px;
}

.bTn {
  display: flex;
  flex-flow: wrap;
  gap: 5px;
}

.bTn.formBtn {
  margin-top: 20px;
}

.text-left.bTn,
.text-left .bTn {
  justify-content: flex-start;
}

.text-center.bTn,
.text-center .bTn {
  justify-content: center;
}

.text-right.bTn,
.text-right .bTn {
  justify-content: flex-end;
}

/*_____ upperlay _____*/

.upperlay {
  position: fixed;
  top: 0;
  left: 280px;
  right: 0;
  bottom: 0;
  background: rgb(10 33 48 / 0.9);
  opacity: 0;
  visibility: hidden;
  backdrop-filter: blur(5px);
  transition: all ease 0.5s;
  z-index: 100;
}

.upperlay.active {
  opacity: 1;
  visibility: visible;
}

/*
  |----------------------------------------------------------------------
  |       Popup
  |----------------------------------------------------------------------
  */

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 33, 37, 0.95);
  padding: 20px;
  overflow: auto;
  z-index: 101;
}

.popup-full {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  padding: 80px 0;
  overflow: auto;
  z-index: 101;
}

.popup-full .back_btn {
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 15px;
  width: 28px;
  height: 28px;
  color: #000000;
  font-size: 35px;
  text-align: center;
  cursor: pointer;
  z-index: 5;
}

.popup-full ._inner {
  position: relative;
  padding: 0px 15px;
}

.popup ._inner {
  position: relative;
  max-width: 600px;
  /*background: #f7f8f9;*/
  background: #fff;
  padding: 20px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.popup.big-popup ._inner {
  max-width: 800px;
}

.popup.small-popup ._inner {
  max-width: 420px;
}

.popup ._inner h3 {
  padding-right: 25px;
  margin-bottom: 20px;
}

.popup ._inner h4 {
  padding-right: 25px;
  margin-bottom: 15px;
}

.popBtn {
  cursor: pointer;
}

.crosBtn {
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 15px;
  width: 28px;
  height: 28px;
  color: #8b9593;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  z-index: 5;
}

.crosBtn:before {
  content: "\2715";
  line-height: 1;
}

.popup .list>li {
  display: flex;
  margin-bottom: 10px;
}

.popup .list>li>div:nth-child(1) {
  width: 120px;
  min-width: 120px;
  font-family: "Bold";
  margin-right: 10px;
}

.popup .list>li>div:nth-child(2) {
  width: 100%;
}

.popup .blockLst {
  margin: -15px;
}

/*
  |----------------------------------------------------------------------
  |       Dropdown
  |----------------------------------------------------------------------
  */

.dropBtn {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

._dropBtn {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.dropBtn>i.chevron {
  display: inline-block;
  width: 10px;
  min-width: 10px;
  height: 10px;
  background-image: var(--chevron);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0;
  margin-left: 5px;
  transition: inherit;
  opacity: 0.7;
}

.dropDown {
  position: relative;
  z-index: 1;
}

._dropDown {
  position: relative;
  z-index: 3;
}

.dropCnt {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 160px;
  max-width: 260px;
  background: #fff;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  /* border: 1px solid #eee; */
  border-radius: 7px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition: all ease 0.5s;
  overflow: hidden;
}

._dropCnt {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 300px;
  max-width: 300px;
  background: #fff;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  /* border: 1px solid #eee; */
  border-radius: 7px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition: all ease 0.5s;
  overflow: hidden;
  z-index: 3;
}

.dropCnt.right {
  left: initial;
  right: 0;
}

.dropCnt.active {
  margin-top: 0;
  visibility: visible;
  opacity: 1;
}

._dropCnt.active {
  margin-top: 15px;
  visibility: visible;
  opacity: 1;
}

.dropLst {
  /* border: 1px solid #eee; */
  /* padding: 6px 0; */
  /* border-radius: 7px; */
}

.dropLst>li,
.dropLst>li>* {
  display: block;
}

/* .dropLst>li:nth-child(1)>* {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
  }
  
  .dropLst>li:nth-last-child(1)>* {
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
  }
  
  .dropLst>li:not(:nth-last-child(1)) {
      border-bottom: 1px solid #eee;
  } */

.dropLst>li>* {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  /* color: #fff; */
  text-align: left;
  padding: 6px 15px;
  border: 0;
  font-size: 14px;
}

.dropLst>li>*:hover {
  background: #fafafa;
}

.dropLst>li>*>i {
  margin-right: 6px;
}

/*_____ more _____*/

.more {
  position: relative;
  width: 20px;
  min-width: 20px;
  height: 20px;
  /* padding-bottom: 10px; */
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.5s;
}

.more>span,
.more>span:before,
.more>span:after {
  width: 4px;
  height: 4px;
  border-radius: 75%;
  background: #aaa;
  transition: all ease 0.5s;
  z-index: 2;
}

.more>span:before,
.more>span:after {
  position: absolute;
  content: "";
}

.more>span:before {
  left: 0;
}

.more>span:after {
  right: 0;
}

.more:hover>span,
.more:hover>span:before,
.more:hover>span:after {
  background: #fff;
}

/*
  |----------------------------------------------------------------------
  |       Page Loader
  |----------------------------------------------------------------------
  */

#pageloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5f6f8;
  z-index: 999999;
}

.loader {
  width: 40px;
  height: 40px;
  border: 4px solid #0071bd;
  border-right: 4px solid #f0f5f9;
  border-radius: 75%;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/*
  |----------------------------------------------------------------------
  |       Ico Css
  |----------------------------------------------------------------------
  */

.ico,
.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ico {
  background: #fff;
  border-radius: 75%;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  /* overflow: hidden; */
}

.ico a,
.icon a {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ico img,
.icon img {
  width: 100%;
  height: 100%;
  object-position: center;
}

.ico img {
  object-fit: cover;
  border-radius: inherit;
}

.icon img {
  object-fit: contain;
}

.ico>i.status {
  position: absolute;
  top: 0;
  right: -4px;
  display: block;
  width: 12px;
  height: 12px;
  /* background: #43b753; */
  background: #0071bd;
  border: 2px solid #fff;
  border-radius: 75%;
}

.ico>i.status.active {
  background: #43b753;
  /* background: #0071bd; */
}

.ico>i.status.away {
  background: #ffcb00;
}

.ico>i.status.busy {
  background: #5883ff;
}

.ico>i.status.offline {
  background: #ff5465;
}

.ico>.loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 50%; */
  min-width: 100%;
  height: 3px;
  background: #eee;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  overflow: hidden;
}

.ico>.loading:before {
  content: "";
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0071bd;
}

/*
  |----------------------------------------------------------------------
  |       RateYo
  |----------------------------------------------------------------------
  */

.jq-ry-container {
  position: relative;
  display: block;
  cursor: pointer;
  box-sizing: content-box;
  line-height: 0;
  direction: ltr;
}

.jq-ry-container[readonly="readonly"] {
  cursor: default;
}

.jq-ry-container>.jq-ry-group-wrapper {
  position: relative;
  width: 100%;
}

.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group {
  position: relative;
  line-height: 0;
  white-space: nowrap;
  z-index: 1;
}

.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group>svg {
  display: inline-block;
}

.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group.jq-ry-normal-group {
  width: 100%;
}

.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group.jq-ry-rated-group {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  overflow: hidden;
  z-index: 3;
}

/*
  |----------------------------------------------------------------------
  |       Editor
  |----------------------------------------------------------------------
  */

.ckEditor {
  /* Font */
  /* Emoji fonts are added to visualise them nicely in Internet Explorer. */
  font-family: "Regular", sans-serif, Arial, Verdana, "Trebuchet MS",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;

  /* Text color */
  color: var(--gray);
}

.ckEditor .cke_editable {
  font-size: 13px;
  line-height: 1.6;

  /* Fix for missing scrollbars with RTL texts. (#10488) */
  word-wrap: break-word;
}

.ckEditor blockquote {
  font-style: italic;
  font-family: Georgia, Times, "Times New Roman", serif;
  padding: 2px 0;
  border-style: solid;
  border-color: #ccc;
  border-width: 0;
}

.ckEditor .cke_contents_ltr blockquote {
  padding-left: 20px;
  padding-right: 8px;
  border-left-width: 5px;
}

.ckEditor .cke_contents_rtl blockquote {
  padding-left: 8px;
  padding-right: 20px;
  border-right-width: 5px;
}

.ckEditor a {
  display: inline;
  color: var(--honey);
}

.ckEditor ol,
.ckEditor ul,
.ckEditor dl {
  /* IE7: reset rtl list margin. (#7334) */
  *margin-right: 0px;
  /* Preserved spaces for list items with text direction different than the list. (#6249,#8049)*/
  padding: 0 40px;
}

.ckEditor ul,
.ckEditor ol {
  padding: 0;
  padding-left: 20px;
  margin: 0 0 10px;
  list-style-position: unset;
}

.ckEditor ul>li,
.ckEditor ol>li {
  display: list-item;
  margin-bottom: 5px;
}

.ckEditor ul>li ul,
.ckEditor ol>li ol {
  margin: 5px 0 7px;
}

.ckEditor h1,
.ckEditor h2,
.ckEditor h3,
.ckEditor h4,
.ckEditor h5,
.ckEditor h6 {
  font-weight: normal;
  line-height: 1.2;
  color: var(--black);
}

.ckEditor hr {
  border: 0px;
  border-top: 1px solid #ccc;
}

.ckEditor img.right {
  border: 1px solid #ccc;
  float: right;
  margin-left: 15px;
  padding: 5px;
}

.ckEditor img.left {
  border: 1px solid #ccc;
  float: left;
  margin-right: 15px;
  padding: 5px;
}

.ckEditor pre {
  white-space: pre-wrap;
  /* CSS 2.1 */
  word-wrap: break-word;
  /* IE7 */
  -moz-tab-size: 4;
  tab-size: 4;
}

.ckEditor .marker {
  background-color: Yellow;
}

.ckEditor span[lang] {
  font-style: italic;
}

.ckEditor figure {
  text-align: center;
  outline: solid 1px #ccc;
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 10px 20px;
  display: inline-block;
}

.ckEditor figure>figcaption {
  text-align: center;
  display: block;
  /* For IE8 */
}

.ckEditor a>img {
  padding: 1px;
  margin: 1px;
  border: none;
  outline: 1px solid #0782c1;
}

/* Widget Styles */
.ckEditor .code-featured {
  border: 5px solid red;
}

.ckEditor .math-featured {
  padding: 20px;
  box-shadow: 0 0 2px rgba(200, 0, 0, 1);
  background-color: rgba(255, 0, 0, 0.05);
  margin: 10px;
}

.ckEditor .image-clean {
  border: 0;
  background: none;
  padding: 0;
}

.ckEditor .image-clean>figcaption {
  font-size: 0.9em;
  text-align: right;
}

.ckEditor .image-grayscale {
  background-color: white;
  color: #666;
}

.ckEditor .image-grayscale img,
.ckEditor img.image-grayscale {
  filter: grayscale(100%);
}

.ckEditor .embed-240p {
  max-width: 426px;
  max-height: 240px;
  margin: 0 auto;
}

.ckEditor .embed-360p {
  max-width: 640px;
  max-height: 360px;
  margin: 0 auto;
}

.ckEditor .embed-480p {
  max-width: 854px;
  max-height: 480px;
  margin: 0 auto;
}

.ckEditor .embed-720p {
  max-width: 1280px;
  max-height: 720px;
  margin: 0 auto;
}

.ckEditor .embed-1080p {
  max-width: 1920px;
  max-height: 1080px;
  margin: 0 auto;
}

.ckEditor i,
.ckEditor em {
  font-style: italic;
}

.ckEditor b,
.ckEditor strong {
  font-weight: bold;
}

.ckEditor u {
  text-decoration: underline;
}

.ckEditor span {
  display: initial;
  text-decoration: inherit;
}

/*_____ scrollbar  _____*/

.scrollbar::-webkit-scrollbar-track {
  background: #e3e8ed;
  border-radius: 50px;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: #e3e8ed;
  border-radius: 50px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
}

/*
  |----------------------------------------------------------------------
  |       Header
  |----------------------------------------------------------------------
  */

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  background: #fff;
  box-shadow: 0 5px 15px rgb(0 0 0 / 7%);
  z-index: 30;
}

header .contain-fluid {
  padding: 0 15px;
}

.logo {
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* width: 174px; */
  width: 135px;
  height: 72px;
}

.logo>a {
  display: block;
  height: 100%;
}

.logo>a>img {
  display: block;
  width: auto;
  height: 100%;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left center;
  object-position: left center;
  -webkit-transition: inherit;
  transition: inherit;
}

nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 72px;
  margin-left: 80px;
}

nav>ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 -15px;
}

nav>#nav {
  /* margin-right: 30px; */
  margin-left: auto;
}

nav>ul>li {
  position: relative;
  padding: 0 15px;
}

nav>ul>li>a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 36px;
  /* color: #080a0f; */
  color: var(--gray);
  text-align: center;
}

nav>ul>li>a:hover,
nav>ul>li.active>a:not(.webBtn) {
  color: #6e5650;
}

#nav>li.drop>a {
  position: relative;
}

#nav>li.drop>a:after {
  content: "\f107";
  font-size: 12px;
  font-family: "FontAwesome";
  font-weight: bold;
  margin-left: 6px;
  line-height: 1;
}

.hide-big {
  display: none;
}

#nav>li>.sub {
  position: absolute;
  top: 100%;
  left: 15px;
  width: 240px;
  background: #fff;
  text-align: left;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  border-radius: 7px;
  border: 1px solid rgb(0 0 0 / 3%);
  transition: none;
  box-shadow: 0 15px 30px -5px rgb(0 0 0 / 7%);
  overflow: hidden;
}

#nav>li:hover>.sub {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  transition: all 0.2s ease-out;
}

#nav>li>.sub>li,
#nav>li>.sub>li>a {
  display: block;
}

#nav>li>.sub>li>a {
  background: #fff;
  font-size: 13px;
  padding: 5px 15px;
  border-bottom: 1px solid #f7f7f7;
  text-transform: capitalize;
}

#nav>li>.sub>li>a:hover {
  color: var(--honey);
}

nav>ul>li>.sub.sm {
  width: 240px;
}

nav>ul>li.drop:hover>.sub {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

nav>ul#iconBtn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 -10px;
}

nav>ul#iconBtn>li {
  padding: 0 10px;
}

nav>ul#iconBtn.iconSearch {
  display: none;
}

nav>ul#iconBtn.iconSearch {
  /* margin-right: 10px; */
  margin-right: auto;
  left: 80px;
  right: auto;
}

nav>ul#iconBtn.iconSearch button {
  width: 27px;
  height: 27px;
}

nav>ul .sub .list>li {
  display: block;
}

nav>ul .sub .list>li:not(:nth-last-child(1)) {
  margin-bottom: 10px;
}

nav>ul .sub .list>li>a small {
  display: block;
  color: #8b9593;
}

nav>ul .sub .list>li:nth-last-child(1)>a {
  color: #ff4b4b;
}

nav>ul .iconBtn {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: 20px;
  height: 20px;
  background: transparent;
  padding: 0;
  border: 0;
}

nav>ul .iconBtn>img {
  /* filter: brightness(0) invert(0.5); */
  -webkit-transition: inherit;
  transition: inherit;
}

nav>ul .iconBtn>em {
  position: absolute;
  top: -4px;
  right: -3px;
  -webkit-transition: inherit;
  transition: inherit;
  width: 10px;
  height: 10px;
}

header .srchBar {
  position: relative;
  min-width: 350px;
  margin: 0;
  margin-left: 5%;
}

header .srchBar:before {
  /* content: ''; */
  position: absolute;
  top: 0;
  left: -15px;
  right: -15px;
  bottom: 0;
  background: inherit;
  z-index: -1;
}

header .srchBar>.txtBox {
  height: 40px;
  background: rgb(243 249 253);
  color: #080a0f;
  padding-left: 40px;
  border-color: rgb(243 249 253);
  border-radius: 7px;
}

header .srchBar>.txtBox:focus {
  border-color: #2d8cff !important;
}

header .srchBar>.txtBox::-webkit-input-placeholder {
  color: inherit;
}

header .srchBar>.txtBox::-moz-placeholder {
  color: inherit;
}

header .srchBar>.txtBox:-ms-input-placeholder {
  color: inherit;
}

header .srchBar>.txtBox::-ms-input-placeholder {
  color: inherit;
}

header .srchBar>.txtBox::placeholder {
  color: inherit;
}

header .srchBar>button {
  position: absolute;
  top: 0;
  left: 14px;
  bottom: 0;
  width: 18px;
  height: 18px;
  background: transparent;
  padding: 0;
  margin: auto;
  border: 0;
  /* filter: brightness(0) invert(0.5); */
  -webkit-filter: brightness(0) invert(0);
  filter: brightness(0) invert(0);
}

header .srchBar>button[type="button"] {
  display: none;
  left: initial !important;
  right: 15px !important;
}

nav #cta {
  margin-left: 40px;
}

/* nav .blnsBtn {
      font-size: 22px;
      margin-right: 20px;
      line-height: 1;
      text-align: right;
  }
  
  nav .blnsBtn>small {
      display: block;
      color: #2d8cff;
      font-size: 10px;
  } */

nav .blnsBtn {
  color: #2d8cff;
  font-size: 12px;
  font-family: "SemiBold";
  padding: 3px 7px;
  margin-right: 20px;
  line-height: 1;
  text-align: right;
  border: 2px solid;
  border-radius: 4px;
}

/*_____ proIco _____*/

header .proIco,
.proIco {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
}

.popup .proIco {
  margin: 0 0 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.requestContent .badge {
  cursor: pointer;
}

.requestContent h5 {
  margin: 0;
  margin-left: 5px;
}

header .proIco .inside {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header .proIco .ico,
.proIco .ico {
  width: 36px;
  height: 36px;
  background: #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(59, 65, 94, 0.1),
    0 1px 1px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 5px 0 rgba(59, 65, 94, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07);
}

header .proIco .proDrop {
  left: initial;
  right: 0;
  min-width: 220px;
  max-height: calc(100vh - 90px);
  -webkit-box-shadow: rgb(0 0 0 / 10%) 0 4px 12px 0;
  box-shadow: rgb(0 0 0 / 10%) 0 4px 12px 0;
  overflow: auto;
  /* padding: 7px 0px; */
}

/* header .proIco .proDrop.active {
      margin-top: 5px;
  } */

header .proIco .dropLst>li>a {
  display: block;
  font-family: "Medium";
}

header .proIco .dropLst>li:nth-last-child(1)>a {
  color: #2d8cff;
}

header .proIco .dropLst>li>a>small {
  display: block;
  color: #8b9593;
  font-size: 80%;
  font-family: "Regular";
}

header .proIco .dropLst>li:nth-last-child(1)>a {
  color: #ff4b4b;
}

header .proIco .proDrop li {
  display: block;
}

header .proIco .proDrop li a {
  display: flex;
  padding: 5px 10px;
}

header .proIco .proDrop li:not(:last-child) a {
  border-bottom: 1px solid #f4f1eb;
}

header .proIco .proDrop li a .small_icon {
  width: 16px;
  align-self: center;
}

header .proIco .proDrop li a span {
  flex: 1;
  margin-left: 7px;
  font-size: 13px;
  align-self: center;
}

header .proIco .proDrop li .user_header {
  padding: 10px;
  background: var(--honey);
  text-align: center;
  color: #fff;
}

header .proIco .proDrop li .user_header h5 {
  margin-bottom: 0px;
  font-size: 14px;
}

header .proIco .proDrop li .user_header p {
  font-size: 11px;
  color: #f4f1eb;
  font-family: SemiBold;
}

.loged-nav li a {
  width: 16px;
  /* overflow: hidden; */
  position: relative;
}

.loged-nav li a img {
  height: 100%;
  width: 100%;
}

.loged-nav li {
  padding: 0 10px;
}

.loged-nav {
  margin-right: 0;
}

.loged-nav li a span.active {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: var(--honey);
  top: 7px;
  right: 1px;
  z-index: 1;
}

.ring_bell {
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

/*_____ Header Fixed _____*/

header.fix {
  background: #fff;
  -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 7%);
  box-shadow: 0 5px 15px rgb(0 0 0 / 7%);
}

header.fix .logo {
  -webkit-filter: none;
  filter: none;
}

header.fix nav>ul>li:not(.active)>a:not(.webBtn):not(:hover) {
  color: #080a0f;
}

header.fix form>.txtBox {
  color: #080a0f;
  border-color: #eee;
}

header.fix form>button {
  -webkit-filter: brightness(0) invert(0.5);
  filter: brightness(0) invert(0.5);
}

/*_____ miniLbl _____*/

.miniLbl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: #0071bd;
  color: #fff;
  font-size: 70%;
  font-family: "SemiBold";
  border-radius: 75%;
  white-space: nowrap;
}

.green {
  background: #43b753;
}

.green-color {
  color: #43b753;
}

.yellow {
  background: #ffcb00;
}

.yellow-color {
  color: #ffcb00;
}

.red {
  background: #ff5465;
}

.red-color {
  color: #ff5465;
}

.blue {
  background: var(--honey);
}

.blue-color {
  color: var(--honey);
}

.gray {
  background: #aaa;
}

.gray-color {
  color: #aaa;
}

section {
  padding: 90px 0;
}

/*_____ owl-nav _____*/

.owl-carousel.owl-theme .owl-nav {
  position: absolute;
  top: 55%;
  left: 0;
  right: 0;
  margin: 0;
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  top: 0;
  width: initial;
  height: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #6e5650;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #ffffff;
  background: #000;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.owl-carousel.owl-theme .owl-nav .owl-prev {
  left: 10px;
  padding-right: 2px;
}

.owl-carousel.owl-theme .owl-nav .owl-next {
  right: 10px;
  padding-left: 2px;
}

/*_____ owl-dots _____*/

.owl-carousel.owl-theme .owl-dots {
  text-align: center;
  position: relative;
  margin-top: 20px;
  line-height: 0;
  z-index: 5;
}

.owl-carousel.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  padding: 0 2px !important;
}

.owl-carousel.owl-theme .owl-dots .owl-dot span {
  display: block;
  width: 10px;
  height: 4px;
  margin: auto;
  border: 2px solid #d2d2d2;
  border-radius: 50px;
  transition: all ease 0.5s;
}

.owl-carousel.owl-theme .owl-dots .owl-dot.active span {
  width: 24px;
  height: 4px;
  margin: auto;
  border-color: #6e5650;
}

.active_btn {
  background: #b5936e !important;
  color: #fff;
}

/*
  |----------------------------------------------------------------------
  |       Form
  |----------------------------------------------------------------------
  */

label {
  display: block;
  font-weight: normal;
  cursor: pointer;
}

label>a {
  color: var(--honey);
}

label>a:hover {
  color: var(--honey);
}

.txtGrp {
  position: relative;
}

.txtGrp .ck-editor__editable {
  min-height: 200px;
}

.txtGrp:not(:nth-last-child(1)) {
  margin-bottom: 20px;
}

.infoHead {
  margin-top: 40px;
  margin-bottom: 20px;
}

form h5+p {
  margin: -10px 0 20px;
}

.txtBox {
  display: block;
  width: 100%;
  height: 46px;
  background: #fefefe;
  text-align: left;
  padding: 6px 12px;
  border: 1px solid #eee;
  border-radius: 6px;
  outline: none !important;
  transition: all ease 0.5s;
  font-size: 14px;
}

.txtBox.StripeElement {
  /* display: flex;
  flex-wrap: wrap;
  align-items: center; */
  min-height: 46px;
  padding: 14px 12px;
}

.txtGrp label~.txtBox:not(button) {
  padding: 16px 12px 6px;
}

.txtBox:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

textarea.txtBox {
  height: 120px;
  resize: none;
}

/* .txtGrp .txtBox {
      box-shadow: inset 1px 1px 4px #f2f3f7;
  } */

.txtGrp .txtBox.uploadImg:not([data-upload]) {
  background: #fff;
  color: #0071bd;
  text-align: center;
  border-color: #0071bd;
  box-shadow: inset 1px 1px 4px #fcfcfc;
}

.txtGrp .txtBox.uploadImg[data-upload] {
  display: flex;
  align-items: center;
  color: #8b9593;
}

.txtGrp .txtBox.uploadImg[data-upload]:before {
  content: attr(data-text);
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* .txtGrp .txtBox.uploadImg[data-upload]:after {
    content: "Hochladen";
    color: #0071bd;
    margin-left: 5px;
  }
  
  .txtGrp .txtBox.uploadImg[data-upload].uploaded:after {
    content: "Entfernen";
  } */

.txtGrp:not(.lblBtn)>label {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  min-height: 46px;
  color: #8b9593;
  line-height: 1;
  margin: 0;
  margin-left: 13px;
  pointer-events: none;
  transition: all ease 0.5s;
  z-index: 1;
}

.txtGrp:not(.lblBtn)>label.move {
  font-size: 10px;
  transform: translateY(-12px);
}

.txtGrp .company_image {
  width: 130px;
  margin-top: 10px;
}

.txtGrp .company_image img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.txtBox:disabled {
  background: #e3e8ee;
  cursor: not-allowed;
  border-color: #d8dee6 !important;
}

.txtBox::placeholder {
  color: #8b9593;
  opacity: 1;
  font-size: 12px;
}

.txtBox:focus::placeholder {
  opacity: 0.4;
}

.txtGrp .txtBox:focus::placeholder {
  opacity: 0;
}

.txtBox:hover,
.txtBox:focus {
  border-color: #b5936e;
}

input[type="radio"],
input[type="checkbox"] {
  position: relative;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-top: 0;
  cursor: pointer;
}

input[type="radio"],
input[type="checkbox"] {
  display: inline;
  appearance: initial;
}

input[type="radio"]:before,
input[type="checkbox"]:before,
input[type="radio"]:after,
input[type="checkbox"]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

input[type="checkbox"]:before,
input[type="checkbox"]:after {
  transition: all linear 0.3s;
}

input[type="radio"]:before,
input[type="checkbox"]:before {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  z-index: 1;
}

input[type="radio"]:after,
input[type="checkbox"]:after {
  background: #fff;
  border: 1px solid #d9dade;
  border-radius: 4px;
  box-shadow: inset 1px 1px 4px #f2f3f7;
}

input[type="radio"]:after {
  border-radius: 75%;
}

input[type="checkbox"]:checked:before {
  background-image: var(--tick);
}

input[type="radio"]:checked:after {
  border-color: var(--honey);
  box-shadow: inset 0 0 0 4px var(--honey);
}

input[type="checkbox"]:checked:after {
  background: var(--honey);
  border-color: var(--honey);
  box-shadow: none;
}

.lblBtn {
  display: flex;
  text-align: left;
}

.current_working_checkbox {
  width: 100%;
  justify-content: end;
}

.lblBtn>input[type="radio"],
.lblBtn>input[type="checkbox"] {
  margin-top: 2px;
}

.lblBtn label {
  align-self: center;
  font-family: "SemiBold";
  line-height: 1.5;
  cursor: pointer;
  margin: 0 0 0 10px;
}

input[type="file"].uploadFile {
  display: none;
}

.uploadImg {
  position: relative;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

select.txtBox {
  background-image: var(--chevron);
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 10px;
  padding-right: 24px !important;
  -webkit-appearance: none;
  appearance: none;
}

.active_pkg {
  border: 2px solid #e98d2a;
  box-shadow: 0 10px 10px !important;
}


/*
  |----------------------------------------------------------------------
  |       Banner
  |----------------------------------------------------------------------
  */

#banner {
  /* height: 90vh; */
  /* background: url(../src/assets/images/5.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: #006fc0; */
  display: flex;
  justify-content: center;
  flex-direction: column;

  align-items: center;
  padding-bottom: 0;
  /* padding-top: 0; */
}

#banner .contain {
  width: 100%;
  height: 100%;
}

#banner .inner_cntnt {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 8rem;
  /* align-items: center; */
}

/* #banner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0000009d;
    opacity: 0.7;
    z-index: 1;
  } */

#banner .content {
  max-width: 45%;
  color: #fff;
  margin-bottom: 0px;
}

.right_banner_image {
  position: absolute;
  width: 45%;
  right: 0;
  bottom: 0;
}

.right_banner_image .main_image {
  /* width: 430px; */
  width: 620px;
  overflow: hidden;
  margin-left: auto;
  position: relative;
}

.small_img1 {
  position: absolute;
  width: 230px;
  /* height: 140px; */
  border-radius: 5px;
  overflow: hidden;
  top: 25%;
  left: -70px;
  /* animation-name: float-bob3;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      -moz-animation-name: float-bob3;
      -moz-animation-duration: 4s;
      -moz-animation-iteration-count: infinite;
      -moz-animation-timing-function: linear;
      -ms-animation-name: float-bob3;
      -ms-animation-duration: 4s;
      -ms-animation-iteration-count: infinite;
      -ms-animation-timing-function: linear;
      -o-animation-name: float-bob3;
      -o-animation-duration: 4s;
      -o-animation-iteration-count: infinite;
      -o-animation-timing-function: linear; */
  filter: drop-shadow(2px 4px 16px rgba(75, 75, 75, 0.616));
}

.small_img1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.small_img2 {
  position: absolute;
  width: 200px;
  border-radius: 5px;
  overflow: hidden;
  top: 13%;
  left: 390px;
  filter: drop-shadow(2px 4px 16px rgb(75, 75, 75));
  /*filter: drop-shadow(2px 4px 16px rgb(75, 75, 75));
  animation-name: float-bob4;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: float-bob4;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob4;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob4;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
   */

  -webkit-filter: drop-shadow(2px 4px 16px rgb(75, 75, 75));
}

.small_img3 {
  position: absolute;
  width: 200px;
  border-radius: 5px;
  overflow: hidden;
  top: 73%;
  left: 60px;
  filter: drop-shadow(2px 4px 16px rgb(75, 75, 75));
  /* filter: drop-shadow(2px 4px 16px rgb(75, 75, 75));
    animation-name: float-bob3;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob3;
    -moz-animation-duration: 4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob3;
    -ms-animation-duration: 4s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob3;
    -o-animation-duration: 4s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear; */
}

#banner .content>h1 {
  font-size: 55px;
  line-height: 1.1;
  margin: 0px 0px 20px;
  text-shadow: 5px 5px 5px rgb(41 41 47 / 20%);
  color: rgb(255, 255, 255);
}

#banner .content>h1 em {
  display: block;
}

#banner .content>h1 span {
  font-family: "SemiBold";
}

#banner .content>p {
  font-size: 16px;
  text-shadow: 2px 2px 2px rgb(41 41 47 / 20%);
  font-family: "Light";
  margin-bottom: 20px;
  color: rgb(255, 255, 255);
}

.banner-form {
  max-width: 45%;
}

.banner-form h3 {
  margin-bottom: 20px;
  color: #0c4352;
}

.banner-form .col {
  flex: 2;
}

.banner-form .col ._txtGrp {
  position: relative;
}

.banner-form .col ._txtGrp i {
  position: absolute;
  left: 10px;
  top: 17px;
  font-size: 18px;
}

/* .banner-form .col_last{
      flex: 1;
  } */
.banner-form .webBtn {
  min-width: fit-content;
  padding: 0;
  width: 140px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-form .webBtn i {
  margin-right: 0;
}

.banner-form .txtBox {
  height: 50px;
  border-radius: 0;
  border-color: #088fff00;
  border-left: 1px solid #ddd;
  padding-left: 40px;
}

.banner-form .txtBox::placeholder {
  color: rgb(160, 160, 160);
}

.banner-form form .flex {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.banner-form form .flex .col:first-child .txtBox {
  border-left: none;
}

.banner-form ul,
.banner-form p {
  margin-top: 20px;
}

.banner-form p {
  color: rgb(255 255 255 / 81%);
}

/* .banner-form ul li{
    display: block;
      padding-left: 25px;
      position: relative;
      color: rgb(255 255 255 / 81%);
      margin: 7px 0px;
  }
  .banner-form ul li:before {
    position: absolute;
    content: "\e92f";
    top: -3px;
    left: 0px;
    font-family: font-icon;
    font-size: 18px;
    color: rgb(255 255 255 / 81%);
  } */

@-webkit-keyframes float-bob3 {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  50% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }

  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@-webkit-keyframes float-bob4 {
  0% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }

  50% {
    -webkit-transform: translateY(-35px);
    transform: translateY(-35px);
  }

  100% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }
}

/* ========================================== */
.sec_heading {
  margin-bottom: 30px;
}

.sec_heading h3 {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--honey);
  font-family: "SemiBold";
}

.sec_heading h2 {
  font-size: 40px;
  font-family: "SemiBold";
  /* text-transform: capitalize; */
  color: var(--black);
}

.cate_sec {
  background-color: #e0d8c8;
  padding-bottom: 75px !important;
}

.cate_sec .listing {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.cate_sec .sec_heading {
  margin-bottom: 30px;
}

.cate_sec .item {
  width: 25%;
  padding: 10px;
}

.cate_sec .item .inner {
  width: 100%;
  height: 100%;
  /*box-shadow: 10px 10px 20px 0px rgb(0 0 0 / 20%);
    */
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #ffffff;
  display: flex;
}

.cate_sec .item .inner .image {
  width: 70px;
  height: 70px;
  background: var(--honey);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin: 0px 0px;
  padding: 12px;
}

.cate_sec .item .inner ._cntnt {
  flex: 1;
  margin-left: 15px;
  align-self: center;
}

.cate_sec .item .inner .image img {
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}

.cate_sec .item .inner h1 {
  color: #b5936e;
}

.cate_sec .item .inner h4 {
  font-size: 17px;
  margin-bottom: 3px;
}

.cate_sec .item .inner p {
  color: #b3ab9b;
  font-size: 13px;
}

.cate_sec .owl-carousel.owl-theme .owl-nav {
  top: -20%;
}

.cate_sec .owl-carousel.owl-theme .owl-nav .owl-prev {
  left: auto;
  right: 50px;
}

/* =======================ticket_grid=============== */
.ticket_grid {
  margin: -10px;
}

.ticket_grid .col {
  width: 25%;
  padding: 10px;
}

.ticket_grid .col .inner {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 20px #ddd;
}

.wishlist {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #0071bd;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s ease;
}

.wishlist:hover {
  transition: all 0.5s ease;
  background-color: #fff;
  color: #0071bd;
}

.ticket_grid .col .inner .image {
  width: 100%;
  height: 95px;
  overflow: hidden;
}

.ticket_grid .col .inner .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ticket_grid .col .inner .ticket_cntnt {
  padding: 5px 10px 10px;
}

.ticket_grid .col .inner .ticket_cntnt h4 {
  font-size: 17px;
  font-family: "Bold";
}

.ticket_info_flex {
  background: #f1f1f1;
  padding: 5px 5px;
  border-radius: 2px;
}

.ticket_info_flex div {
  flex: 1;
  display: flex;
  gap: 7px;
  margin-right: 5px;
  border-radius: 2px;
  font-size: 13px;
}

.ticket_info_flex div.price {
  flex: 0.5;
  display: flex;
  background: #0071bd;
  justify-content: center;
  padding: 0px 5px;
  margin-right: 0;
  color: #fff;
  font-size: 14px;
  font-family: "SemiBold";
}

.ticket_location {
  background: #dbecf7;
  padding: 5px 5px;
  border-radius: 2px;
  margin-top: 6px;
  font-size: 13px;
  display: flex;
  gap: 7px;
}

.seller_info {
  margin-top: 10px;
  background: #f1f1f1;
  padding: 5px;
  border-radius: 2px;
}

.seller_info .seller_dp {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.seller_info .seller_dp img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.seller_info .seller_bio {
  align-self: center;
  flex: 1;
  margin-left: 10px;
}

.done_sell {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 11px;
  width: 70px;
  text-align: center;
  border-left: 1px solid #ffff;
}

.done_sell h6 {
  margin-bottom: 4px;
  font-size: 17px;
  color: #0071bd;
  font-family: "SemiBold";
}

.done_sell p {
  line-height: 1.2;
  color: #333;
}

.seller_info .seller_bio h5 {
  margin-bottom: 2px;
  font-size: 15px;
  color: #0071bd;
  font-family: "SemiBold";
  color: #333;
}

.seller_info .seller_bio .rating {
  font-size: 12px;
  color: #333;
}

.seller_info .seller_bio .rating i {
  color: #0071bd;
}

.ticket_grid .col .inner .bTn a {
  width: 100%;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  margin-top: 10px;
  height: 40px;
}

/* ================about_sec============ */
/* .welcom{
    
    padding-top: 160px;
  } */
.welcom:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #f3f3f3;
}

.welcom .colL {
  width: 35%;
  padding-right: 30px;
  align-self: center;
}

.welcom.candidate_welcom_pg .colL {
  width: 50%;
}

.welcom .colR {
  width: 65%;
  padding-left: 30px;
}

.welcom.candidate_welcom_pg .colR {
  width: 50%;
}

.welcom .colR .bTn {
  margin-top: 20px;
}

.welcom .colR h5 {
  color: #0071bd;
  font-family: "SemiBold";
  font-size: 15px;
}

.welcom .colL .col,
.welcom .colR .col {
  width: 100%;
}

.welcom .colL .flex,
.welcom .colR .flex {
  margin: -15px;
}

.welcom .colL .col .inner,
.welcom .colR .col .inner {
  background: #ffffff;
  padding: 35px 30px;
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.welcom .colL .col .inner:not(:last-child),
.welcom .colR .col .inner:not(:last-child) {
  margin-bottom: 15px;
}

.welcom .colL .col .inner .img-ico,
.welcom .colR .col .inner .img-ico {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 15px;
  position: relative;
}

.welcom .colL .col .inner .inner_cntnt,
.welcom .colR .col .inner .inner_cntnt {
  flex: 1;
  align-self: center;
  margin-left: 10px;
}

.welcom .colL .col .inner h3,
.welcom .colR .col .inner h3 {
  font-size: 18px;
  text-transform: uppercase;
}

.welcom .colL .col .inner:first-child .img-ico,
.welcom .colR .col .inner:first-child .img-ico {
  /* border:1px solid #24e42f; */
  background: #50e4582e;
}

.welcom .colL .col .inner:nth-child(2) .img-ico,
.welcom .colR .col .inner:nth-child(2) .img-ico {
  /* border:1px solid #b92ff0; */
  background: #e3adf975;
}

.welcom .colL .col:nth-child(2) .inner:last-child .img-ico,
.welcom .colR .col:nth-child(2) .inner:last-child .img-ico {
  background: #adf0f975;
}

.welcom .colL .col:first-child .inner:last-child .img-ico,
.welcom .colR .col:first-child .inner:last-child .img-ico {
  /* border:1px solid #f024a2; */
  background: #f9f3a87d;
}

.welcom .colL .col .inner .img-ico:before,
.welcom .colR .col .inner .img-ico:before {
  top: -6px;
}

.welcom .colL .col .inner .img-ico:after,
.welcom .colR .col .inner .img-ico:after {
  bottom: -6px;
}

.welcom .colL .col .inner p,
.welcom .colR .col .inner p {
  color: #8b9593;
}

/* =================how_it_works================== */
.how_it_works .col {
  width: calc(100% / 3);
  padding: 10px;
  text-align: center;
}

.how_it_works {
  background-color: #f8f8f8;
}

.how_it_works .col .image {
  width: 80px;
  height: 80px;
  margin: 0px auto 10px;
  filter: hue-rotate(45deg);
  background: #4182e838;
  border-radius: 50%;
  padding: 10px;
}

.how_it_works .col:nth-child(2) .image {
  background: #41e8a838;
}

.how_it_works .col:nth-child(3) .image {
  background: #415ae838;
}

.how_it_works .col .inner {
  padding: 55px;
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

/* .how_it_works .col .inner:after{
      position: absolute;
      content: '';
      top: -40px;
      left: -15px;
      width: 107px;
      height: 102px;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      background-color: #0071bd;
  } */
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* .how_it_works .col .inner:before{
    position: absolute;
      counter-increment: my-sec-counter;
      content: "Step " counter(my-sec-counter);
      top: 10px;
      left: 15px;
      font-size: 20px;
      color: #ffffff;
      z-index: 1;
  } */

.location .flex {
  margin: -10px;
}

.location .col {
  width: 20%;
  padding: 10px;
}

.location .col .inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.location .col .inner .image {
  width: 100%;
  height: 170px;
  overflow: hidden;
  position: relative;
}

.location .col .inner .image:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #00000061;
  z-index: 1;
}

.location .col .inner .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.location .col .inner .image:hover img {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.location .col .inner h4 {
  position: absolute;
  margin-bottom: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  color: #fff;
  padding: 15px;
  font-size: 17px;
}

/* ============sellers_sec============ */
.sellers_sec .colL {
  width: 50%;
  padding-right: 30px;
  align-self: center;
}

.sellers_sec .colR {
  width: 50%;
  padding-left: 20px;
}

.sellers_sec .colR ._col {
  width: 50%;
  padding: 10px;
}

.sellers_sec {
  background: #f3f3f3;
}

.sellers_sec .seller_info {
  background: #ffffff;
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
  margin-top: 0;
}

.sellers_sec .seller_info .done_sell {
  border-color: #f3f3f3;
}

/* =============testimonial_sec============= */
.testimonial_sec {
  background-color: #ffffff;
}

.testimonial_sec .colL {
  width: 100%;
  /* padding-right: 20px; */
  text-align: center;
  margin-bottom: 20px;
}

.testimonial_sec .colR {
  width: 100%;
}

/* .testimonial_sec .colR .item {
    width: calc(100% / 3);
    padding: 10px;
  } */
.testimonial_sec .colR .item {
  padding: 10px;
}

.testimonial_sec .colR .item .comnt_testi {
  background-color: #f6f6f6;
  /* box-shadow: 0 30px 60px 0 rgb(0 0 0 / 12%); */
  padding: 15px;
  position: relative;
  margin-bottom: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.testimonial_sec .colR .item .comnt_testi:after {
  position: absolute;
  content: "";
  bottom: -17px;
  left: 9%;
  border-top: 17px solid #f6f6f6;
  border-right: 17px solid #5f5f5f00;
}

.testimonial_sec .colR .item .testi_icon {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  overflow: hidden;
  /* box-shadow: 0 30px 60px 0 rgb(0 0 0 / 52%); */
  border: 1px solid #f6f6f6;
}

.testimonial_sec .colR .item .testi_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial_sec .colR .item .flex h5 {
  flex: 1;
  margin-left: 10px;
  margin-bottom: 0;
  align-self: center;
}

.stars_revie {
  color: #f89d32;
  margin-bottom: 10px;
}

/*
  |----------------------------------------------------------------------
  |       Footer
  |----------------------------------------------------------------------
  */

footer {
  position: relative;
  background-color: #b5936e;
  color: rgba(255, 255, 255, 0.925);
  font-family: "Light";
  padding-top: 60px;
  overflow: hidden;
  font-size: 14px;
}

footer:before {
  background-image: url(../src/assets/images/footer-background.png);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  /* position: absolute; */
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.5;
}

footer .flexRow {
  flex-flow: nowrap;
  margin: -25px -15px;
}

footer .flexRow>.col {
  flex: 1;
  padding: 25px 15px;
}

footer .flexRow>.col:nth-last-child(1) {
  min-width: 300px;
  flex: 1.5;
}

footer .flexRow>.col.flex {
  align-items: flex-start;
  justify-content: flex-end;
}

footer h5 {
  position: relative;
  font-family: "Regular";
  margin-bottom: 30px;
  padding-bottom: 10px;
  color: #fff;
  font-size: 16px;
}

footer h5:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background: #ffffff;
}

footer .lst>li {
  display: block;
  margin-bottom: 10px;
}

footer .lst>li:nth-last-child(1) {
  margin-bottom: 0 !important;
}

footer a {
  color: inherit;
  font-size: 14px;
}

footer a:hover {
  color: #ffffff;
}

footer .smLst {
  margin: 0 -10px;
}

footer .smLst>li {
  padding: 0 10px;
}

footer .infoLst>li {
  display: flex;
  margin-bottom: 20px;
}

footer .infoLst>li>img {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 10px;
  /* filter: brightness(0) invert(1); */
}

footer form .txtGrp {
  display: flex;
  align-items: center;
  color: #080a0f;
  margin: 10px 0 30px;
}

footer form .txtBox {
  padding-right: 46px;
}

footer form button {
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  background: transparent;
  font-size: 16px;
  padding: 0;
  line-height: 0;
  border: 0;
}

footer form>label {
  font-size: 13px;
  margin-bottom: 7px;
  font-family: "Regular";
}

footer .social {
  margin: 30px -3px -3px;
}

footer .social>li {
  padding: 3px;
}

footer .social>li>a {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 38px;
  min-width: 38px;
  height: 38px;
  /* background: #fff; */
  background: rgb(255 255 255 / 10%);
  padding: 11px;
  border-radius: 75%;
}

footer .social>li>a:hover {
  background: #e0d8c8;
}

footer .social>li>a>img {
  filter: brightness(0) invert(1);
}

/*_____ Copyright _____*/

.copyright {
  padding: 25px 0;
  margin-top: 60px;
  border-top: 1px solid rgb(255 255 255 / 7%);
  z-index: 1;
}

.copyright .inner {
  display: flex;
  justify-content: center;
  /* text-align: center; */
}

.copyright .inner ul {
  display: flex;
  gap: 40px;
}

.copyright p {
  flex: 1;
  margin: 0;
  text-align: center;
}

.copyright p>a {
  color: #ffffff;
}

.copyright p>a:hover {
  color: #fff;
}

/* =============================================outer_banner==================== */
.outer_banner {
  /* background: url(../src/assets/images/5.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.outer_banner .cntnt {
  max-width: 900px;
  margin: 0px auto;
  text-align: center;
  color: #fff;
}

.choose_flex {
  margin: 0 -10px;
}

.choose_flex .col {
  width: 50%;
  padding: 10px;
}

.choose_flex .inner {
  width: 100%;
  background: #ffffff;
  padding: 35px 30px;
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.choose_flex .inner .side_icon {
  width: 65px;
  height: 65px;
  margin-right: 25px;
  position: relative;
}

.choose_flex .inner .side_icon:after {
  position: absolute;
  content: "";
  top: 18px;
  left: 0;
  width: 65px;
  height: 16px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background-color: #e0d8c85e;
  transform: rotate(-45deg);
}

.choose_flex .inner .side_icon img {
  position: relative;
  z-index: 1;
}

.choose_flex .inner .cntnt {
  flex: 1;
}

.choose_flex .inner .cntnt h4 {
  color: var(--honey);
}

/* ==================video_sec=============== */
.video_sec {
  background-color: #e0d8c85e;
  /* margin-bottom: 60px; */
}

.videoBlk {
  position: relative;
  display: block;
  /*background: #000;
    */
  padding-bottom: 60%;
  overflow: hidden;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

video[poster] {
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
}

.videoBlk video {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  width: 100%;
  margin: 0% auto;
  /* background: #000; */
}

.videoBtn {
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  height: 75px;
  width: 75px;
  margin: -20px auto 0px;
  background: rgb(255 255 255);
  color: var(--honey);
  font-size: 24px;
  border: 2px solid #f3226700;
  border-radius: 75%;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: all linear 0.5s;
  -o-transition: all linear 0.5s;
  transition: all linear 0.5s;
  z-index: 5;
}

.videoBtn::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 20px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  animation: ripple-wave 1s linear infinite;
  animation-play-state: running;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

@keyframes ripple-wave {
  from {
    box-shadow: 0 0 rgb(255 255 255/10%), 0 0 0 15px rgb(255 255 255/10%),
      0 0 0 30px rgb(255 255 255/10%);
  }

  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.1),
      0 0 0 30px rgba(255, 255, 255, 0.1), 0 0 0 45px rgba(255, 255, 255, 0.02);
  }
}

.videoBtn.fa-pause:before {
  padding-left: 0px;
}

.videoBtn.fa-pause {
  opacity: 0;
}

.videoBlk:hover .videoBtn.fa-pause {
  opacity: 1;
}

.video_sec .colL {
  width: 60%;
  padding-right: 30px;
}

.video_sec .colR {
  width: 40%;
  padding-left: 30px;
  align-self: center;
}

/* =================more_info_blk=========== */
.more_info_blk {
  /* background-image: url(../src/assets/images/bg.png); */
  background-color: var(--honey);
  background-position: 0px -131px;
  background-size: cover;
  background-repeat: no-repeat;
}

.more_info_blk .cntnt {
  max-width: 700px;
  margin: 0px auto;
  text-align: center;
  color: #fff;
}

.more_info_blk .cntnt .bTn {
  justify-content: center;
}

.more_info_blk .cntnt h3 {
  font-family: "SemiBold";
  color: #fff;
  font-size: 35px;
}

/* ============about us page============= */
.abt_page {
  background: #fff;
}

.abt_page:before {
  display: none;
}

.why-we .cntnt {
  width: 55%;
  padding-right: 40px;
  align-self: center;
}

.why-we .outer-image {
  width: 45%;
  padding-left: 10px;
}

.why-we .outer-image .abt-image {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding-bottom: 90%;
  position: relative;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
}

.why-we .outer-image .abt-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.why-we ul {
  margin-bottom: 10px;
}

.why-we ul li {
  display: block;
  padding-left: 25px;
  position: relative;
  color: #000;
  margin: 3px 0px;
}

.why-we ul li:before {
  position: absolute;
  content: "\e92f";
  top: -3px;
  left: 0px;
  font-family: font-icon;
  font-size: 18px;
  color: #0071bd;
}

.why-we {
  background: #e0d8c85e;
}

/* =================cta_sec============ */

.cta_sec {
  background: #e0d8c8;
  padding: 150px 0px !important;
}

.cta_sec .cntnt {
  width: 50%;
  align-self: center;
}

.cta_sec .colR {
  width: 50%;
  padding-left: 30px;
  position: relative;
}

.cta_sec .colR .cta_sec_right_img {
  width: 250px;
  margin-left: 0;
  border-radius: 10px;
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.cta_sec .colR .img1 {
  position: absolute;
  right: 0;
  top: -63px;
  height: 250px;
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.cta_sec .colR .img2 {
  position: absolute;
  right: 0%;
  bottom: -63px;
  width: 300px;
  height: 197px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.cta_sec .colR img {
  height: 100%;
  object-fit: cover;
}

/* ==============================become_seller_banner========================= */

.become_seller_banner {
  /* background: url(../src/assets/images/5.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.become_seller_banner .cntnt {
  max-width: 700px;
  margin: 0px auto;
  text-align: center;
  color: #fff;
}

.become_how_sec:before {
  display: none;
}

.feature_flexible .bTn {
  justify-content: center;
  margin-top: 30px;
}

.feature_flexible .colL {
  width: 30%;
}

.feature_flexible .colL .inner {
  background-color: #0071bd;
  padding: 60px 50px 60px 30px;
  color: #fff;
}

.feature_flexible .colL .inner h3 {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 100px;
}

.feature_flexible .colR {
  width: 70%;
}

.feature_flexible .colR .inner {
  background-color: #fff;
  margin-left: -50px;
  margin-top: 50px;
}

.feature_flexible .colR .flex .col {
  width: calc(100% / 3);
  padding: 42px;
  border: 1px solid #dddddd52;
  background: #f3f9fd;
}

.feature_flexible .colR .flex .col h4 {
  font-size: 14px;
  /* letter-spacing: 1px; */
  text-transform: uppercase;
  line-height: 1.3;
}

.feature_flexible .colR .flex .col .img_icon {
  width: 50px;
  margin-bottom: 10px;
}

/* ==================contact us============ */
.contact_sec .flex {
  background-color: #ffffff;
  max-width: 1000px;
  margin-left: auto;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: -1px 1px 20px 20px rgb(1 72 120 / 5%);
}

.contact_sec .colL {
  width: 30%;
  /* padding-right: 40px; */
  align-self: center;
}

.contact_sec .colR {
  width: 70%;
  padding: 50px;
}

.contact_sec .colL .inner {
  background: url(../src/assets/images/5.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px;
  margin-left: -205px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: #fff;
}

.contact_sec .colL .inner ul {
  margin: 20px 0px;
}

.contact_sec .colL .inner ul li {
  display: flex;
  margin: 10px 0px;
  flex-wrap: wrap;
}

.contact_sec .colL .inner ul li i {
  font-size: 16px;
  color: var(--honey);
  margin-right: 10px;
  /* align-self: center; */
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.contact_sec .colL .inner ul li .cntact_lnks {
  flex: 1;
  color: #fff;
}

.contact_sec .colL .inner .webBtn {
  background-color: #fff;
  color: var(--honey);
  margin-top: 20px;
}

.contact_sec .colR form .form_blk {
  margin-bottom: 20px;
}

.contact_sec .colR .txtBox::placeholder {
  color: var(--gray);
  opacity: 0.6;
}

/* ================support_banner=========== */
.support_banner .banner-form {
  max-width: 100%;
}

.support_banner .banner-form i {
  color: #0000006b;
  z-index: 1;
}

.support_banner .banner-form .txtBox {
  color: var(--gray) !important;
  font-size: 14px;
  font-family: "Regular";
}

.support_banner .banner-form .txtBox::placeholder {
  font-size: 14px;
  font-family: "Regular";
}

.customer_support .blk_support .flex {
  margin: -10px;
  justify-content: center;
}

.customer_support .blk_support:not(:last-child) {
  margin-bottom: 60px;
}

.customer_support .blk_support .col {
  width: calc(100% / 3);
  padding: 10px;
}

.customer_support .blk_support .col .inner {
  width: 100%;
  background: #ffffff;
  padding: 35px 30px;
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-left: 2px solid #0071bd;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.customer_support .blk_support .col .inner span {
  width: 30px;
  /* height: 30px; */
  font-size: 30px;
  color: #0071bd;
}

.customer_support .blk_support .col .inner .cntnt {
  flex: 1;
}

.customer_support .blk_support .col .inner .cntnt h3 {
  color: #0071bd;
  margin-bottom: 5px;
  font-size: 22px;
}

.customer_support .blk_support .col .inner .cntnt p {
  color: #333;
}

.customer_support .blk_support .col .inner:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border-left-color: #fff;
}

/* ===================faq============== */
.faq_sec {
  background: #e0d8c85e;
}

.faqLst {
  max-width: 900px;
  margin: 30px auto 0px;
}

.faqBox>.faqLst:not(:nth-last-child(1)) {
  margin-bottom: 40px;
}

.faqLst>.faqBlk {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 20px 0;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: all ease 0.5s;
}

.faqLst>.faqBlk:nth-last-child(1) {
  margin-bottom: 0;
}

.faqLst>.faqBlk h5 {
  position: relative;
  padding: 0 40px 0 25px;
  margin: 0;
  cursor: pointer;
  transition: all ease 0.5s;
  z-index: 2;
  font-family: "SemiBold";
}

.faqLst>.faqBlk h5:after {
  content: "\f107";
  position: absolute;
  top: -2px;
  right: 20px;
  font-size: 20px;
  font-family: "FontAwesome";
  font-weight: bold;
}

.faqLst>.faqBlk.active h5:after {
  content: "\f106";
}

.faqLst>.faqBlk h5:hover {
  color: var(--honey);
}

.faqLst>.faqBlk .txt {
  display: none;
  color: #252525;
  margin: 0 0 -20px;
  padding: 20px 25px;
}

.faqLst>.faqBlk .txt {
  display: block;
}

/* ======================================logon==================== */

.logon {
  background: #e0d8c85e;
}

.logon .log_blk {
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
}

.log_blk>form {
  background: #fff;
  padding: 25px;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
}

.log_blk .haveAccount {
  margin-top: 20px;
}

.log_blk .haveAccount a {
  color: var(--honey);
}

.log_blk .haveAccount a:hover {
  color: #080a0f;
}

.log_blk #pass {
  margin-left: auto;
}

/*_____ pasDv _____*/

.txtGrp.pasDv {
  position: relative;
}

.txtGrp.pasDv i {
  position: absolute;
  /* top: -12px; */
  bottom: 0;
  right: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 18px;
  height: 100%;
  cursor: pointer;
  opacity: 0.7;
  -webkit-filter: brightness(0.5) invert(0.4);
  filter: brightness(0.5) invert(0.4);
}

.txtGrp.pasDv i {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.txtGrp.pasDv i.icon-eye {
  background-image: url("../src/assets/images/icon-eye.svg");
}

.txtGrp.pasDv i.icon-eye-slash {
  background-image: url("../src/assets/images/icon-eye-slash.svg");
}

.txtGrp.pasDv i:hover {
  opacity: 1;
}

.txtGrp.pasDv .txtBox {
  padding-right: 40px;
}

/* ===============text-pages=========== */

.text-pages .text-blk {
  background: #ffffff;
  box-shadow: 0 15px 38px rgb(0 0 0 / 10%);
  padding: 40px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid #ececec;
  margin-top: 30px;
}

.text-blk ul li,
.text-blk ol li {
  display: block;
  position: relative;
}

.text-blk ul li:before,
.text-blk ol li:before {
  position: absolute;
  content: "";
  top: 5px;
  left: -20px;
  width: 7px;
  height: 7px;
  background: var(--honey);
}

/* ==========================seller profile=================== */
.profile_banner {
  background: #f6f9fc;
}

.outer_flex_customer_profile {
  margin-bottom: 40px;
}

.outer_flex_customer_profile .outer_colL {
  width: 30%;
  padding-right: 20px;
}

.outer_flex_customer_profile .outer_colR {
  width: 70%;
  padding-left: 20px;
}

.seller_tile {
  padding: 25px;
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  /* height: 100%; */
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

._available_tickets_grid .col {
  width: calc(100% / 3) !important;
}

.mini_outer_flex_profile .outer_colL {
  width: 25% !important;
}

.mini_outer_flex_profile .outer_colR {
  width: 75% !important;
}

.seller_tile .colL {
  padding-bottom: 20px;
}

.seller_tile .colM {
  align-self: center;
  /* background: #0071bd;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
      color: #ffffffe3;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px; */
}

.seller_tile .colR {
  padding-top: 20px;
  align-self: center;
}

.seller_tile .colL .seller_dp {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0px auto 10px;
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
}

.seller_tile .colL .seller_dp img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.seller_tile .colL .seller_cntnt {
  text-align: center;
}

.seller_tile .colL .seller_cntnt h3 {
  color: #000;
  font-family: "SemiBold";
  margin-bottom: 5px;
}

.seller_tile .colL h6 {
  color: #0071bd;
  font-size: 13px;
}

.seller_tile .rating i {
  color: #0071bd;
}

.seller_tile .rating {
  font-size: 13px;
  color: #333;
  text-align: center;
}

.seller_tile .colM ul li {
  display: block;
  display: flex;
}

.seller_tile .colM ul li:not(:last-child) {
  margin-bottom: 10px;
}

.seller_tile .colM ul li strong {
  flex: 2;
}

.seller_tile .colM ul li em {
  flex: 0.5;
}

.seller_tile .colR .bTn a {
  width: 100%;
}

.location_seller {
  margin-bottom: 10px;
  border-bottom: 1px solid #f5f8fb;
  padding-bottom: 10px;
}

.cstm_blk {
  padding: 35px;
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 30px;
}

.cstm_blk h3 {
  font-family: "SemiBold";
  text-transform: uppercase;
  font-size: 20px;
}

/* ==================seller_profile_footer============ */
.seller_profile_footer .review_blk {
  width: 70%;
  padding: 35px;
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.seller_profile_footer .question_blk {
  width: 30%;
  padding-left: 20px;
}

.review_header {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.review_header h3 {
  flex: 1;
  margin-right: 20px;
  margin-bottom: 0;
  font-family: "SemiBold";
  text-transform: uppercase;
  font-size: 20px;
}

.review_header .rating {
  font-size: 13px;
  align-self: center;
  margin-bottom: -3px;
}

.review_header .rating i {
  color: #0071bd;
}

.review_lsting {
  /* box-shadow: 0px 15px 38px rgb(0 0 0 / 10%); */
  margin: 20px 0px;
  padding: 15px 15px;
  /* border-bottom: 1px solid #ddd; */
  background: #f6f9fc;
  border-radius: 10px;
}

.review_lsting:not(:last-child) {
  margin-bottom: 20px;
}

.review_lsting .customer_icon {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.review_lsting .customer_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.review_lsting .customer_cmnt {
  flex: 1;
  margin-left: 20px;
}

.review_lsting .customer_cmnt h4 {
  font-size: 18px;
  font-family: "Medium";
  margin-bottom: 5px;
}

.review_lsting .customer_cmnt .time_cmnt {
  position: relative;
  display: flex;
  gap: 25px;
  font-size: 12px;
  margin-bottom: 10px;
  color: #0071bd;
}

.review_lsting .customer_cmnt .time_cmnt span {
  position: relative;
}

.review_lsting .customer_cmnt .time_cmnt span:first-child:after {
  position: absolute;
  content: "";
  top: 1px;
  right: -13px;
  bottom: 1px;
  width: 1px;
  background-color: #0071bd;
}

.review_lsting .customer_cmnt p {
  font-style: italic;
  color: rgb(94, 94, 94);
}

.seller_profile_footer {
  margin-top: 40px;
}

.small_heading {
  font-family: "SemiBold";
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 20px;
}

.leav_review_blk {
  margin-top: 30px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.leav_review_blk .form_blk {
  margin-bottom: 20px;
}

.leav_review_blk .bTn .webBtn {
  width: 100%;
}

.rating_star {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 10px 12px;
  border: 1px solid #eee;
  color: #8f8f8f;
}

.rating_star h5 {
  font-size: 14px;
}

.rating_customer_review {
  position: absolute;
  top: 20px;
  right: 20px;
}

/* ================================ticket_detail_blk================= */
.ticket_detail_blk .image {
  max-width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin-bottom: 10px;
}

.ticket_detail_blk h4 {
  font-size: 18px;
}

.ticket_detail_blk {
  max-width: 350px;
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background-color: #fff;
  padding: 30px;
}

.ticket_detail_blk .bTn {
  margin-top: 10px;
}

.ticket_detail_blk .bTn .webBtn {
  width: 100%;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  margin-top: 10px;
  height: 40px;
}

.note_blk {
  margin-top: 40px;
  display: flex;
  gap: 13px;
  background: #ffffff;
  padding: 35px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.note_blk span {
  font-size: 20px;
  color: #ff6f08;
  height: 20px;
  display: flex;
}

.note_blk h5 {
  text-transform: uppercase;
  font-family: "SemiBold";
}

.margin-bottom-zero {
  margin-bottom: 0px;
}

.checkout_ticket_blk {
  margin-bottom: 40px;
}

.checkout_seller_blk .colL {
  padding-bottom: 0;
}

.checkout_blk {
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background-color: #fff;
  padding: 30px;
}

.checkout_blk h3 {
  font-size: 18px;
  margin-bottom: 20px;
  font-family: "SemiBold";
  text-transform: uppercase;
}

/* ==================payment method============== */
#payment-form .headCredit {
  margin: 0px;
  background: #f5f8fb;
  border-bottom: 1px solid #fff;
}

.chk .headCredit {
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  background: #f5f8fb;
  margin: 0px -15px;
}

#payment-form .flex-2 .inner .lblBtn {
  display: flex;
}

#payment-form .flex-2 .inner .lblBtn label {
  color: rgb(0, 0, 0);
  align-self: center;
  margin-bottom: 0;
}

#payment-form .flex-2 .inner .lblBtn input[type="radio"] {
  top: auto;
}

#payment-form label {
  color: #ddd;
}

.creditCard .headCredit .inner {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#payment-form .cardSec {
  padding: 10px 10px;
  /*border: 1px solid #4b4b4b;*/
  border-top: none;
  margin-bottom: 10px;
  background: #f5f8fb;
}

#payment-form .flex-2 .inner {
  width: 50%;
  padding: 5px;
  position: relative;
}

#payment-form .flex-2.headCredit .inner:last-child ul {
  display: flex;
  justify-content: flex-end;
}

.creditCard .paypalSec {
  display: none;
}

.creditCard .creditSec {
  display: none;
}

.chk .creditCard .paypalSec .ico,
.chk .creditCard .creditSec .ico {
  max-width: 160px;
  margin: 20px auto 10px;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
}

.chk .creditCard .paypalSec .ico img,
.chk .creditCard .creditSec .ico img {
  border-radius: 0px;
}

.chk .creditCard .paypalSec p,
.chk .creditCard .creditSec p {
  color: #bcbcbc;
}

.creditSecBar {
  margin-top: 10px !important;
}

.sq-field {
  margin-top: 20px;
}

/* .sq-field button{
    font-size: 20px!important;
    min-width: 180px!important;
  } */
.chk .creditCard .cardSec .inner .info {
  position: absolute;
  top: 18px;
  right: 14px;
}

.chk .creditCard .cardSec .inner .info>i {
  width: 18px;
  height: 18px;
  padding-top: 2.4px;
  text-align: center;
  border: 1px solid #aaa;
  color: #aaa;
  border-radius: 75%;
  font-size: 12px;
  display: block;
  padding-left: 0.3px;
  cursor: pointer;
}

.chk .creditCard .cardSec .inner .info>.infoTip {
  position: absolute;
  z-index: 10;
  /*color: #333;*/
  color: #222222;
  width: 160px;
  top: 22px;
  left: -115px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  text-align: justify;
  -moz-text-align-last: center;
  text-align-last: center;
  font-size: 12px;
  padding: 5px 10px 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 6px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 6px 0px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.chk .creditCard .cardSec .inner .info>.infoTip:before {
  position: absolute;
  content: "";
  left: 120px;
  top: -3px;
  border-top: 8px solid #ffffff;
  border-left: 8px solid #ffffff;
  -webkit-box-shadow: #ffffff 1px -1px 0px;
  box-shadow: #ffffff 1px -1px 0px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.chk .creditCard .cardSec .inner .info:hover .infoTip {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#payment-form .payment_card {
  margin-top: 40px;
}

/* =====post_job_sec===== */
.post_job_sec {
  padding-bottom: 140px !important;
}

.applicant_list {
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  bottom: -50px;
  right: -100px;
  background: #ffffff;
  box-shadow: 0px 40px 30px rgb(25 25 46 / 4%);
}

.applicant_list .head_listing {
  background-color: var(--honey);
  padding: 10px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}

.applicant_list ul {
  padding: 20px;
}

.applicant_list ul li {
  display: flex;
  flex-wrap: wrap;
  padding: 7px 10px;
}

.applicant_list ul li .small_icon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}

.applicant_list ul li .small_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.applicant_list ul li .cntnt {
  flex: 1;
  margin-left: 10px;
  align-self: center;
}

.applicant_list ul li .cntnt h4 {
  color: var(--black);
  font-size: 16px;
  margin-bottom: 0;
}

.applicant_list ul li .cntnt p {
  color: var(--gray);
  font-size: 13px;
  opacity: 0.7;
}

.post_job_sec .colL {
  width: 50%;
  padding-right: 10px;
  position: relative;
}

.post_job_sec .colR {
  width: 50%;
  padding-left: 150px;
  align-self: center;
}

.post_job_sec .colL .image {
  width: 100%;
  padding-bottom: 90%;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}

.post_job_sec .colL .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.post_job_sec .colR ul li {
  position: relative;
  display: block;
  padding-left: 25px;
  margin: 6px 0px;
}

.post_job_sec .colR ul li:before {
  position: absolute;
  content: "\e92f";
  top: -3px;
  left: 0px;
  font-family: font-icon;
  font-size: 18px;
  color: var(--gray);
}

.post_job_sec .colR .bTn {
  margin-top: 30px;
}

/* ====job_listing_grid==== */
/* .job_listing_grid{
    background: url(../src/assets/images/bg-3.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  } */
.job_listing_grid {
  padding-bottom: 0px !important;
}

.job_listing_grid:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #e0d8c85e;
  clip-path: polygon(0 0, 100% 0%, 100% 50%, 0% 70%);
}

.job_flex {
  margin: -10px;
}

.job_flex .col {
  width: calc(100% / 3);
  padding: 10px;
}

.job_flex .inner {
  background: #ffffff;
  border: 1px solid #ecedf2;
  border-radius: 8px;
  padding: 28px 20px;
  position: relative;
  margin-top: 10px;
}

.job_flex .inner .book_mark {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #f4f1eb;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.job_flex .inner .book_mark:hover,
.job_flex .inner .book_mark.active {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  background-color: var(--honey);
  color: #fff;
}

.job_flex .inner .head_job {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.job_flex .inner .head_job .img_ico {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.job_flex .inner .head_job .img_ico img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.job_flex .inner .head_job .cntnt {
  flex: 1;
  margin-left: 10px;
  align-self: center;
}

.job_flex .inner .head_job .cntnt h4 {
  font-size: 18px;
  margin-bottom: 4px;
  font-family: "Bold";
}

.job_flex .inner .head_job .cntnt p small {
  font-size: 13px;
  color: var(--honey);
}

.job_flex .inner .head_job .cntnt ul,
.candidate_flex_lst .inner .job_bdy ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.candidate_flex_lst .inner .job_bdy ul {
  margin-bottom: 10px;
}

.job_flex .inner .head_job .cntnt ul li,
.candidate_flex_lst .inner .job_bdy ul li {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  position: relative;
  opacity: 0.8;
}

.job_flex .inner .head_job .cntnt ul li i,
.candidate_flex_lst .inner .job_bdy ul li i {
  align-self: center;
  margin-right: 3px;
}

.job_flex .inner .job_bdy {
  margin-bottom: 20px;
}

.job_flex .inner .job_bdy p {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.job_flex .inner .job_bdy .skils span {
  margin-right: 10px;
  background: #f4f1eb;
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 13px;
}

.job_flex .inner .job_footer {
  display: flex;
  justify-content: space-between;
}

.job_flex .inner .job_footer .job_price {
  align-self: center;
  font-size: 20px;
  font-family: "Bold";
  color: var(--honey);
}

.job_flex .inner .job_footer .job_price span {
  font-size: 14px;
  font-family: "Regular";
  color: var(--gray);
}

.job_flex .inner .job_footer .webBtn {
  padding: 0 17px;
  height: 36px;
}

.heading_flex_jobs {
  justify-content: space-between;
}

.heading_flex_jobs .mini_cntnt {
  align-self: center;
}

.heading_flex_jobs .mini_cntnt a {
  display: flex;
  font-size: 14px;
}

.heading_flex_jobs .mini_cntnt a i {
  align-self: center;
  margin-left: 10px;
}

/* ===how-it-work=== */
.how-it-work {
  padding-top: 40px !important;
  /* padding-bottom: 0; */
}

.how-it-work .colL {
  width: 30%;
}

.how-it-work .colL ul li {
  position: relative;
  display: block;
  padding-left: 25px;
  margin: 6px 0px;
}

.how-it-work .colL ul li:before {
  position: absolute;
  content: "\e92f";
  top: -3px;
  left: 0px;
  font-family: font-icon;
  font-size: 18px;
  color: var(--gray);
}

.how-it-work .colL .webBtn {
  margin-top: 30px;
}

.how-it-work .colR {
  width: 70%;
  padding-left: 40px;
  display: flex;
  flex-wrap: wrap;
}

.how-it-work .colR ._col {
  width: 50%;
}

.how-it-work .colR ._col .how_work_heading {
  text-align: center;
  margin-bottom: 20px;
}

.how-it-work .colR ._col .how_work_heading h3 {
  font-size: 20px;
  font-family: "Bold";
}

.how-it-work .colR ._col:nth-child(1) {
  padding-right: 10px;
}

.how-it-work .colR ._col:nth-child(2) {
  padding-left: 10px;
}

.how-it-work .colR ._col ul li {
  position: relative;
  background: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 10px 15px 25px rgb(0 0 0 / 5%);
  border: 1px solid #eef4fd;
}

.how-it-work .colR ._col ul li ._cntnt {
  margin-left: 35px;
  display: flex;
}

.how-it-work .colR ._col ul li ._step {
  text-transform: uppercase;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: all 0.5s ease;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  left: 0;
  top: 30px;
  font-family: "SemiBold";
  bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.how-it-work .colR ._col ul li .icon_img {
  width: 50px;
  height: 50px;
  background: #1967d2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 20px;
}

.how-it-work .colR ._col:nth-child(1) ul li:nth-child(1) .icon_img {
  background-color: #00ffd0;
}

.how-it-work .colR ._col:nth-child(1) ul li:nth-child(2) .icon_img {
  background-color: #bc84ca;
}

.how-it-work .colR ._col:nth-child(1) ul li:nth-child(3) .icon_img {
  background-color: #d9c514;
}

.how-it-work .colR ._col:nth-child(2) ul li:nth-child(1) .icon_img {
  background-color: #d9c514;
}

.how-it-work .colR ._col:nth-child(2) ul li:nth-child(2) .icon_img {
  background-color: #cf03f4;
}

.how-it-work .colR ._col:nth-child(2) ul li:nth-child(3) .icon_img {
  background-color: #4caf50;
}

.how-it-work .colR ._col:nth-child(2) ul li:nth-child(4) .icon_img {
  background-color: #00ffd0;
}

.how-it-work .colR ._col ul li ._cntnt h3 {
  font-size: 19px;
  margin-bottom: 3px;
}

.how-it-work .colR ._col ul li ._cntnt p {
  opacity: 0.7;
  font-size: 14px;
}

.how-it-work .colR ._col ul li ._cntnt .inner_cntnt {
  flex: 1;
  margin-left: 10px;
}

/* ===more_info=== */

.more_info:before {
  /* background: url(../src/assets/images/bg-2.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
  position: absolute;
  content: "";
  top: -120px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e0d8c85e;
  clip-path: polygon(0 0, 100% 28%, 100% 100%, 0% 100%);
}

.more_info .colL {
  width: 35%;
}

.more_info .colR {
  width: 65%;
  align-self: center;
}

.more_info .colR .inner {
  background: url(../src/assets/images/5.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  padding: 80px;
}

.more_info .colR .inner #counter {
  margin-top: 20px;
}

.more_info .colR .inner #counter .col {
  width: calc(100% / 3);
  padding: 10px;
}

.more_info .colR .inner #counter .col ._inner h1 {
  color: #fff;
  font-family: "SemiBold";
  font-size: 35px;
}

.more_info .colR .inner #counter .col ._inner h4 {
  color: #fff;
  margin-bottom: 0;
  font-size: 16px;
}

.more_info .colR .inner #counter .col:first-child h1 {
  color: #ffeb3b;
}

.more_info .colR .inner #counter .col:nth-child(2) h1 {
  color: #00ffd0;
}

.more_info .colR .inner #counter .col:nth-child(3) h1 {
  color: #ffa4c3;
}

.more_info .colR .inner .sec_heading h3,
.more_info .colR .inner p {
  color: rgba(255, 255, 255, 0.801);
}

.more_info .colR .inner .sec_heading h2 {
  color: #fff;
}

.more_info .colR .inner .webBtn {
  background-color: #fff;
  color: var(--honey);
  margin-top: 20px;
}

/* ===_cta_sec=== */
._cta_sec {
  padding: 0px 0px 45px !important;
}

._cta_sec .cntnt {
  /* background-image: url(../src/assets/images/bg.png); */
  background-color: var(--honey);
  background-position: 0px -131px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 60px;
  text-align: center;
  position: relative;
}

._cta_sec .cntnt .bTn {
  justify-content: center;
}

._cta_sec .cntnt .bTn .webBtn {
  background-color: #fff;
  color: var(--honey);
}

._cta_sec .cntnt .bTn .webBtn.blankBtn {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
}

._cta_sec .cntnt .sec_heading h2 {
  color: #fff;
}

._cta_sec .cntnt p {
  color: rgba(255, 255, 255, 0.808);
}

.blog_grid {
  margin: -10px;
}

.blog_grid .col {
  width: calc(100% / 4);
  padding: 10px;
}

.blog_grid .col .inner {
  border: 1px solid #ecedf2;
  border-radius: 10px;
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 100%;
}

.blog_grid .col .inner .image {
  display: block;
  position: relative;
  padding-bottom: 70%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
}

.blog_grid .col .inner .image img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.blog_grid .col .inner:hover .image img {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.blog_grid .col .inner .cntnt .date {
  font-size: 13px;
  margin: 7px 0px;
  opacity: 0.7;
}

.blog_grid .col .inner .cntnt h4 {
  font-size: 18px;
  margin-bottom: 5px;
  font-family: "SemiBold";
}

.blog_grid .col .inner .cntnt p {
  opacity: 0.7;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 7px;
}

.blog_grid .col .inner .cntnt .more_read {
  font-size: 14px;
  color: var(--honey);
  display: flex;
  width: fit-content;
  margin-left: auto;
}

.blog_grid .col .inner .cntnt .more_read i {
  align-self: center;
  margin-left: 4px;
  margin-top: 3px;
  font-size: 16px;
}

.parter_logos {
  margin-top: 30px;
}

.parter_logos h4 {
  text-transform: uppercase;
  margin-bottom: 20px;
  color: var(--black);
  font-size: 16px;
}

.parter_logos .inner {
  background-color: #fff;
  width: 135px;
  overflow: hidden;
  height: 50px;
  border-radius: 3px;
  padding: 10px;
}

.parter_logos .inner img {
  height: 100%;
  object-fit: contain;
  filter: sepia(1) invert(1);
}

/* =new_abt_mini_img== */

.new_abt_mini_img {
  position: relative;
}

.new_abt_mini_img .mini_img1 {
  position: absolute;
  top: 0;
}

.new_abt_mini_img .mini_img2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* ===abt_job_sec=== */
.abt_job_sec:before {
  position: absolute;
  content: "";
  bottom: 153px;
  left: 0;
  width: 70%;
  height: 50%;
  background: url(../src/assets/images/shape-2.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.abt_job_sec .colR {
  align-self: flex-end;
}

.abt_job_sec .colL .image {
  margin-bottom: -50px;
  margin-top: 50px;
  width: 540px;
}

.abt_job_sec .colL .applicant_list {
  bottom: auto;
  top: 0px;
}

.search_job_abt_mini {
  position: absolute;
  bottom: 45px;
  right: -50px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 40px 30px rgb(25 25 46 / 4%);
  transform: rotate(-6deg);
}

.cta_bottom_mrgn {
  padding-bottom: 90px !important;
}

.option_grid {
  padding: 0 0 45px !important;
}

.option_grid .flex {
  margin: -20px;
}

.option_grid .col {
  width: 50%;
  padding: 20px;
}

.option_grid .col .inner {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.option_grid .col .inner .cntnt {
  max-width: 60%;
  padding: 40px;
}

.option_grid .col .inner .right_img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.option_grid.employer_grid_home .col .inner .right_img {
  top: 0;
  left: 0;
}

.option_grid.employer_grid_home .col .inner .right_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.option_grid .col:nth-child(1) .inner {
  background: #e0d8c85e;
}

.option_grid .col:nth-child(2) .inner {
  background: #e0d8c85e;
}

.option_grid .col .inner .cntnt .sec_heading {
  margin-bottom: 15px;
}

.option_grid .col .inner .cntnt .sec_heading h2 {
  font-size: 30px;
}

.why_choose_us .sec_heading,
.how_it_works .sec_heading {
  max-width: 700px;
  margin: 0px auto 50px;
}

.choose_flex_small .col {
  width: 25%;
}

.choose_flex_small .col .inner {
  flex-direction: column;
  position: relative;
}

.choose_flex_small .col .inner .side_icon {
  margin-right: 0;
  margin-bottom: 5px;
}

.choose_flex_small .col .inner p {
  font-size: 15px;
}

/* =====job_results==== */
.job_results .colL {
  width: 25%;
  padding-right: 20px;
}

.job_results .colR {
  width: 75%;
  padding-left: 10px;
}

.job_results .colR .col {
  width: 100% !important;
}

.filter_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.filter_top .text {
  color: rgb(95, 95, 95);
  align-self: center;
}

.filter_top .text strong {
  color: #000;
}

.filter_top .txtBox {
  width: 200px;
  background: #e0d8c85e;
  font-size: 14px;
}

.job_results .colL .inner {
  background: #e0d8c85e;
  padding: 20px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.job_results .colL .inner h4 {
  font-size: 15px;
  text-transform: capitalize;
  color: var(--black);
}

.job_results .colL .inner hr {
  border-top: 1px solid #fff;
}

.job_results .colL .inner .txtBox {
  font-size: 14px;
}

.type_list .lblBtn {
  margin: 15px 0px;
}

.type_list .lblBtn label {
  font-size: 14px;
  font-family: "Regular";
}

/* ======job_detail_banner==== */
.job_detail_banner {
  background-color: #e0d8c85e;
}

.job_detail_banner .cntnt {
  /* max-width: 500px;
    margin:0px auto; */
  text-align: center;
}

.job_detail_banner .cntnt h1 {
  margin-bottom: 5px;
  font-size: 35px;
}

.job_detail_banner .cntnt .job_flex_title {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  margin: 0px auto 20px;
}

.job_detail_banner .cntnt .job_flex_title .img_ico {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.job_detail_banner .cntnt .job_flex_title .img_ico img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.job_detail_banner .cntnt .job_flex_title .right_lst {
  text-align: left;
  flex: 1;
  margin-left: 10px;
  align-self: center;
}

.job_detail_banner .cntnt .job_flex_title .right_lst h4 {
  margin-bottom: 2px;
  font-size: 18px;
}

.job_detail_banner .cntnt .job_flex_title .right_lst a {
  font-size: 14px;
  color: var(--honey);
}

.job_detail_banner .cntnt p {
  display: flex;
  gap: 5px;
  font-size: 14px;
  justify-content: center;
}

.job_detail_banner .cntnt p i {
  font-size: 16px;
  align-self: center;
}

/* ===job_detail_sec== */

.job_detail_sec .cntnt .job_detail_grid_pg {
  width: 80%;
  margin: -95px auto 0px;
}

.company-profile-blk {
  width: 80%;
  margin: 0px auto 0px;
}

.company-profile-blk .bTn {
  margin-top: 20px;
}

.job_detail_sec .cntnt .job_detail_grid_pg>ul {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 10px 15px 25px rgb(0 0 0 / 5%);
  border: 1px solid #eef4fd;
  border-radius: 10px;
}

.job_detail_sec .cntnt .job_detail_grid_pg>ul li h4 {
  display: flex;
  gap: 7px;
  color: var(--honey);
  width: fit-content;
  margin: 0px 0px 5px;
}

.job_detail_sec .cntnt .job_detail_grid_pg>ul li h4 i {
  font-size: 16px;
}

.job_detail_sec .cntnt .job_detail_grid_pg>ul li h4 span {
  align-items: center;
  font-size: 14px;
}

.job_detail_sec .cntnt .job_detail_grid_pg>ul li p {
  font-size: 14px;
  margin-bottom: 0;
}

.job_detail_sec .cntnt .job_detail_grid_pg>ul li {
  padding: 20px;
  flex: 1;
  /* text-align: center; */
  align-self: center;
}

.job_detail_sec .cntnt .job_detail_grid_pg>ul li .bTn {
  justify-content: flex-end;
}

.job_detail_hading {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0px;
}

.job_detail_hading h3 {
  flex: 1;
  margin-bottom: 0;
  align-self: center;
}

.job_detail_sec {
  padding: 50px 0px !important;
}

.share_blk {
  /* margin-top: 30px; */
  /* border-top: 1px solid #dddddd8a; */
  padding-top: 20px;
}

.share_blk span {
  align-self: center;
  margin-right: 10px;
}

.share_blk .social {
  margin: 0px -3px -3px;
  align-self: center;
}

.share_blk .social li {
  padding: 3px;
}

.share_blk .social li a {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 27px;
  min-width: 27px;
  height: 27px;
  background: var(--honey);
  padding: 6px;
  border-radius: 3px;
}

.share_blk .social li a>img {
  filter: brightness(0) invert(1);
}

.share_blk>.flex {
  flex: 1;
}

/* =======candidate_pofile==== */
.candidate_pofile {
  background-color: #e0d8c85e;
}

.profile_flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.profile_flex .img_ico {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0px auto 10px;
}

.profile_flex .img_ico img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile_flex .cntnt {
  align-self: center;
  flex: 1;
  margin-left: 20px;
  text-align: center;
}

.profile_flex .cntnt .name {
  font-size: 22px;
  margin-bottom: 4px;
  font-family: "SemiBold";
}

.profile_flex .cntnt ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.profile_flex .cntnt ul li {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  position: relative;
  opacity: 0.8;
}

.profile_flex .cntnt ul li i {
  align-self: center;
  margin-right: 3px;
}

.profile_flex .cntnt .skils span {
  margin-right: 10px;
  background: var(--honey);
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 13px;
  color: #fff;
}

/* ======blog page====== */
.blog_page .colL {
  width: 75%;
  padding-right: 10px;
}

.blog_page .colR {
  width: 25%;
  padding-left: 10px;
}

.blog_page .ctgryBlk {
  background: #fff;
  padding: 20px 20px 10px;
  margin-bottom: 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%),
    0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%),
    0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
  overflow: hidden;
}

.blog_page .colR h4 {
  margin-bottom: 20px;
  font-size: 17px;
  font-family: "Medium";
}

.blog_page .colR .ctgryBlk .ctgryLst>li {
  display: list-item;
  list-style-position: inside;
  line-height: 1.2;
  margin-bottom: 8px;
}

.blog_page .colR .ctgryBlk .ctgryLst>li>a {
  display: inline;
  color: #333;
}

.blog_side_blk {
  margin-bottom: 30px;
}

.blog_side_blk ul li {
  display: flex;
  flex-wrap: wrap;
}

.blog_side_blk ul li:not(:last-child) {
  margin-bottom: 15px;
}

.blog_side_blk ul li .small_image {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  overflow: hidden;
}

.blog_side_blk ul li .small_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog_side_blk ul li .txt {
  flex: 1;
  margin-left: 10px;
  align-self: center;
}

.blog_side_blk ul li .txt h5 {
  font-size: 14px;
  font-family: "Medium";
  margin-bottom: 4px;
}

.blog_date {
  font-size: 12px;
  font-family: "Medium";
  color: #ababab;
}

.tags_list_blog ul {
  display: flex;
  flex-wrap: wrap;
}

.tags_list_blog ul li a {
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 2px;
  color: #a6a3ba;
  font-size: 12px;
  border: 1px solid #e6ebf1;
}

.blog_page .colL .inner_blog {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.blog_page .colL .inner_blog .col {
  width: calc(100% / 3);
  padding: 10px;
}

.blog_page .colL .inner_blog .col .inner .image {
  display: block;
  position: relative;
  background: #fff;
  border-radius: 7px;
  -webkit-box-shadow: 0 3px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 3px 15px 0 rgb(0 0 0 / 5%);
  overflow: hidden;
  padding-bottom: 50%;
}

.blog_page .colL .inner_blog .col .inner .image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog_page .colL .inner_blog .inner .txt {
  padding: 10px 0 0;
}

.blog_page .txt .ctgry {
  color: var(--honey);
  margin-bottom: 5px;
  font-size: 13px;
}

.blog_page .colL .inner_blog .inner .txt h5 {
  margin-bottom: 5px;
}

.blog_page .colL .inner_blog .inner .txt h5 a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 90px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  transition: all 0.5s ease;
}

.blog_page .colL .inner_blog .inner .txt p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 90px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.5s ease;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 5px;
}

.featured_posts {
  margin-bottom: 40px;
}

.featured_posts .flex {
  margin: -10px;
}

.featured_posts .col_feature {
  width: 50%;
  padding: 10px;
}

.featured_posts .col_feature .inner {
  display: flex;
  flex-wrap: wrap;
}

.blog_page {
  padding: 40px 0px !important;
}

.featured_posts .col_feature .inner .image_feature {
  display: block;
  width: 300px;
  padding-bottom: 30%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.featured_posts .col_feature .inner .image_feature img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  object-fit: cover;
}

.featured_posts .col_feature .inner .txt {
  flex: 1;
  margin-left: 20px;
  align-self: center;
}

.featured_posts .col_feature .inner .txt h4 {
  margin-bottom: 5px;
}

.featured_posts .col_feature .inner .txt .blog_date {
  margin-bottom: 5px;
}

.featured_posts .col_feature .inner .txt h4 a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 90px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  transition: all 0.5s ease;
}

.featured_posts .col_feature .inner .txt p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 90px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.5s ease;
  font-size: 13px;
  margin-bottom: 0;
}

/* ===============blog_flex============= */
.blog_flex .blog_detail_blk {
  border: 3px solid rgb(241 241 241 / 54%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
}

.blog_flex .blog_detail_blk .image {
  width: 100%;
  padding-bottom: 60%;
  overflow: hidden;
  position: relative;
}

.blog_flex .blog_detail_blk .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.blog_flex .blog_detail_blk .ckEditor {
  padding: 0px 20px 20px;
}

.blog_flex .blog_detail_blk .ctgry {
  color: var(--honey);
  font-size: 13px;
}

.imp_info {
  padding: 20px;
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}

.imp_info * {
  align-self: center;
}

.imp_info span {
  color: #ddd;
}

.blog_flex .blog_detail_blk ul {
  padding-left: 0;
}

.apply_blk {
  margin-top: 30px;
  border: 1px solid #b5936e38;
  padding: 20px;
  border-radius: 10px;
}

.apply_blk h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

.apply_blk .inner_apply_blk {
  background: #e0d8c85e;
  border: 1px solid #b5936e38;
  padding: 20px;
  border-radius: 10px;
}

.apply_blk .txtBox::placeholder {
  font-size: 12px;
  color: rgb(148, 148, 148);
}

/* ===.packages=== */

.packages .col {
  width: 25%;
  padding: 10px;
}

.packages .col .inner {
  background: #ffffff;
  box-shadow: 0 15px 38px rgb(0 0 0 / 10%);
  overflow: hidden;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.top-package {
  border-bottom: 1px solid #ffe9e9;
  margin-bottom: 20px;
  padding-bottom: 10px;
  text-align: center;
}

.top-package h4 {
  font-family: "Regular";
  font-size: 14px;
}

.top-package h3 {
  font-size: 35px;
  text-transform: uppercase;
  color: var(--honey);
}

.top-package h3 sub {
  font-size: 13px;
}

.top-package h6 {
  font-family: "Regular";
}

.inner-package {
  margin: 10px auto 20px;
}

.inner-package ul li {
  display: block;
  position: relative;
  /* padding-left: 15px; */
  /* margin-bottom: 5px; */
  text-align: center;
  font-size: 14px;
}

.inner-package ul li:last-child {
  margin-bottom: 0;
}

.inner-package ul li small {
  padding: 0px 30px;
  display: block;
  opacity: 0.7;
}

.inner-package ul li i {
  font-size: 12px;
}

/* .inner-package ul li:before{
      position: absolute;
      content: '';
      top: 8px;
      left: 0;
      width: 7px;
      height: 7px;
      background: var(--honey);
  } */
.packages .col .inner .webBtn {
  width: 100%;
}

.packages .col .inner.popular {
  border: 1px solid var(--honey);
  transform: scale(1.06);
  -webkit-transform: scale(1.06);
  -moz-transform: scale(1.06);
  -ms-transform: scale(1.06);
  -o-transform: scale(1.06);
}

.packages .col .inner .mst_popular {
  position: absolute;
  text-transform: uppercase;
  right: 6px;
  top: 8px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border: 1px solid var(--honey);
  font-size: 10px;
  padding: 4px 10px 2px;
  background: var(--black);
  color: #fff;
}

.packages .col .inner .topBtn {
  margin-bottom: 20px;
}

.packages .col .inner .topBtn .webBtn {
  text-transform: uppercase;
  font-size: 16px;
}

.fullWid .fileFlex {
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}

.fullWid .fileFlex span {
  font-size: 20px;
  /* margin-right: 20px; */
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b5936e;
  color: #fff;
}

.fullWid .fileFlex h4 {
  text-align: center;
  align-self: center;
  /* text-transform: uppercase; */
  flex: 1;
  margin-bottom: 0px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border: 1px dashed #b5936e;
  cursor: pointer;
}

a:focus,
a:hover {
  color: inherit;
}

.pagination {
  margin-bottom: 0;
}

.pagination_buttons {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.job_page_featured {
  padding-bottom: 80px !important;
}

.cta_act_btn {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;
}

.cta_act_btn>* {
  background: #f4f1eb;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.cta_act_btn>*:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  background-color: var(--honey);
  color: #fff;
}

.cta_act_btn.column_dir>* {
  width: 25px;
  height: 25px;
  font-size: 13px;
}

/* ===new_logon==== */
.new_logon {
  background: #e0d8c85e;
}

.new_logon .log_blk>form {
  max-width: 800px;
  margin: 0px auto;
}

.new_logon .log_blk>form .fullWid .fileFlex {
  margin-bottom: 0 !important;
}

.new_logon .log_blk>form .fullWid .fileFlex h4 {
  flex-direction: column;
}

.new_logon .log_blk>form h6 {
  margin-bottom: 5px;
  font-size: 13px;
}

.new_logon .log_blk>form h6 span {
  font-size: 1rem;
  color: var(--honey);
}

.new_logon .log_blk>form .bTn {
  margin-top: 20px;
}

.new_logon .log_blk>form .bTn .webBtn {
  width: fit-content;
}

.pricing_suport_blk {
  max-width: 500px;
  margin: 70px auto 50px;
  text-align: center;
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #b5936e;
  position: relative;
}

.pricing_suport_blk .bTn {
  justify-content: center;
}

.new_lbl {
  position: absolute;
  top: -23px;
  right: -15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b5936e;
  color: #fff;
  font-size: 12px;
}

.pricing_popup ._inner h3 {
  margin-bottom: 5px;
}

.m_th ._col {
  width: calc(100% / 6);
  padding: 10px;
}

.m_th ._col ._inner {
  width: 165px;
  height: 165px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border: 1px solid #b5936e;
  padding: 15px;
}

.m_th ._col ._inner h4 {
  font-size: 16px;
  font-family: "SemiBold";
  margin-bottom: 5px;
}

.m_th ._col ._inner p {
  font-size: 12px;
}

.pricing_testi {
  padding-top: 0 !important;
}

.inner_blk_blk .inner_head_blk {
  margin-bottom: 20px;
}

.nav-tabs {
  border-bottom: none;
  margin-bottom: 20px;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover,
.nav-tabs>li>a:hover {
  color: #fff;
  cursor: default;
  background-color: #b5936e;
  border: none;
  border-bottom-color: none;
}

.nav-tabs>li>a {
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  background-color: #080a0f;
  color: #fff;
}

.apply_cta_sec_sec {
  padding: 0 !important;
}

.apply_cta_sec_sec .cntnt {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.new_logon.after_pricing .log_blk form {
  margin: 0;
  max-width: 1000px;
}

.new_logon.after_pricing .sec_heading h2 {
  font-size: 25px;
}

.blk_choose {
  max-width: 500px;
  border: 1px solid #b5936e;
  border-radius: 10px;
  margin-bottom: 30px;
  overflow: hidden;
}

.blk_choose .left_cntnt {
  flex: 1;
  padding: 25px;
}

.blk_choose .right_cntnt {
  background: #b5936e;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blk_choose .right_cntnt h3 {
  margin-bottom: 0;
  color: #fff;
  font-size: 30px;
}

.blk_choose .right_cntnt small {
  color: #fff;
  font-size: 12px;
}

.blk_choose .left_cntnt ul li {
  display: block;
  font-size: 14px;
  padding-left: 10px;
  position: relative;
}

.blk_choose .left_cntnt ul li:before {
  position: absolute;
  content: "";
  top: 9px;
  left: 0;
  width: 5px;
  height: 5px;
  background: var(--honey);
}

.txt_included {
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: none;
}

.txt_included.active {
  display: block;
}

.included_lst {
  margin-bottom: 20px;
}

.included_lst h5 {
  color: var(--honey);
  cursor: pointer;
}

.payment_method_blk .blk {
  background: rgb(255, 255, 255);
  /* padding: 20px; */
  margin-bottom: 20px;
  border-radius: 10px;
}

.creditCard {
  padding: 20px;
}

.payment_method_blk .blk ._header {
  /* margin: -20px -20px 20px; */
  border-bottom: 1px solid #f4f1eb;
  border-radius: 4px 4px 0 0;
}

.payment_method_blk .blk ._header {
  position: relative;
  /* background: #f7fafc; */
  padding: 15px 20px 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.payment_method_blk .blk ._header h3 {
  color: #000000;
  font-size: 16px;
  margin-right: 30px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 0px;
}

.payment_method_blk .headCredit {
  margin: 0px;
  background: #cde7cd;
  border-bottom: 1px solid #fff;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  background: #f1eae5;
  /* margin: 0px -15px; */
}

.payment_method_blk .flex-2 .inner {
  width: 50%;
  padding: 5px;
  position: relative;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  /* align-self: center; */
}

.payment_method_blk .flex-2 .inner .lblBtn {
  display: flex;
}

.payment_method_blk .flex-2 .inner .lblBtn label {
  color: rgb(0, 0, 0);
  align-self: center;
  margin-bottom: 0;
}

.payment_method_blk .cardSec {
  padding: 10px 10px;
  /* border: 1px solid #4b4b4b; */
  border-top: none;
  margin-bottom: 10px;
  background: #f4f1eb;
}

.payment_method_blk .creditCard .cardSec .inner .info {
  position: absolute;
  top: 18px;
  right: 14px;
}

.payment_method_blk .creditCard .cardSec .inner .info>i {
  width: 18px;
  height: 18px;
  padding-top: 2.4px;
  text-align: center;
  border: 1px solid #aaa;
  color: #aaa;
  border-radius: 75%;
  font-size: 12px;
  display: block;
  padding-left: 0.3px;
  cursor: pointer;
}

.payment_method_blk .creditCard .cardSec .inner .info>.infoTip {
  position: absolute;
  z-index: 10;
  /* color: #333; */
  color: #222222;
  width: 160px;
  top: 22px;
  left: -115px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  text-align: justify;
  -moz-text-align-last: center;
  text-align-last: center;
  font-size: 12px;
  padding: 5px 10px 5px;
  -webkit-box-shadow: rgb(0 0 0 / 15%) 2px 2px 6px 0px;
  box-shadow: rgb(0 0 0 / 15%) 2px 2px 6px 0px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.payment_method_blk .creditCard .cardSec .inner .info:hover .infoTip {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.creditCard .headCredit .inner:last-child {
  margin-bottom: -9px;
}

.payment_method_blk {
  max-width: 700px;
}

.payment_method_blk .creditCard .paypalSec .ico,
.payment_method_blk .creditCard .creditSec .ico {
  max-width: 160px;
  margin: 20px auto 10px;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
}

.payment_method_blk .creditCard .paypalSec .ico img,
.payment_method_blk .creditCard .creditSec .ico img {
  border-radius: 0px;
}

.fa-facebook,
.fa-facebook-square {
  color: #3b5998;
}

.fa-twitter,
.fa-twitter-square {
  color: #00aced;
}

.fa-linkedin,
.fa-linkedin-square {
  color: #007bb6;
}

.fa-instagram {
  color: #ff0084;
}

/* ======empoyer Home====== */
.mini_flex_half .cntnt {
  width: 60%;
}

.mini_flex_half .cntnt .sec_heading {
  margin-bottom: 20px;
}

.mini_flex_half .cntnt .sec_heading h2 {
  font-size: 30px;
  text-transform: uppercase;
  opacity: 0.5;
  font-family: "Bold";
}

.text_flex {
  justify-content: center;
  max-width: 1000px;
  margin: 30px auto 10px;
  gap: 50px;
}

.text_flex h4 {
  margin-bottom: 0;
  /* width: 20%; */
  padding: 10px;
  text-align: center;
  color: var(--honey);
}

.icon_icon_flex {
  justify-content: center;
  max-width: 1100px;
  margin: 0px auto -10px;
}

.icon_icon_flex .col {
  width: 20%;
  padding: 10px;
}

.icon_icon_flex .col .inner {
  width: 170px;
  height: 170px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 1px solid var(--honey);
  border-radius: 50%;
  padding: 20px;
}

.icon_icon_flex .col .inner h1 {
  margin-bottom: 0;
}

.icon_icon_flex .col .inner p {
  line-height: 1.4;
}

.employers_strip {
  background-color: #d8c8bb73;
  padding: 50px 0px;
}

.employers_strip .colL {
  width: 80%;
  padding-right: 20px;
}

.employers_strip .colR {
  width: 20%;
  padding-left: 20px;
}

.employers_strip .inner_logo {
  width: 150px;
  margin-left: auto;
}

.employers_strip .colL h4,
.first_blk.flex .cntnt h4 {
  text-transform: uppercase;
  font-size: 25px;
}

.employers_strip .colL .bTn {
  justify-content: center;
  max-width: 400px;
  margin: 0px auto;
}

.employers_strip .colL .bTn .webBtn {
  width: 100%;
}

.first_blk.flex {
  max-width: 1000px;
  border: 1px solid #b5936e;
  padding: 30px;
  border-radius: 10px;
}

.first_blk.flex .cntnt {
  flex: 1;
  margin-right: 20px;
}

.first_blk.flex .bTn {
  width: 300px;
  margin-left: auto;
}

.first_blk.flex .bTn a {
  width: 100%;
}

.second_blk {
  margin: 40px 0 20px;
}

.second_blk h4 {
  text-transform: uppercase;
  font-size: 20px;
}

.second_blk .cntnt h6 {
  text-transform: uppercase;
  color: var(--honey);
}

.second_one {
  margin-top: 20px;
  margin-bottom: 30px;
}

.second_one .left_cntnt {
  width: 15%;
  padding-right: 10px;
}

.second_one .right_cntnt {
  width: 85%;
  padding-left: 10px;
  align-self: center;
}

.second_one .right_cntnt ul li {
  display: block;
  margin-bottom: 10px;
}

.second_one .right_cntnt ul li h6 {
  margin-bottom: 2px;
  font-size: 17px;
  color: #000;
}

.second_one .right_cntnt ul li p {
  font-size: 15px;
}

.progress_img {
  width: 165px;
}

.second_flex_blk_full .left_flex {
  width: 70%;
  padding-right: 20px;
}

.second_flex_blk_full .right_flex {
  width: 30%;
  padding-left: 20px;
  align-self: center;
}

.second_flex_blk_full .right_flex .webBtn {
  width: 100%;
}

.second_flex_blk_full .left_flex .inner i {
  font-size: 30px;
  align-self: center;
}

.second_flex_blk_full .left_flex .inner ._inner {
  flex: 1;
  margin-left: 15px;
}

.second_flex_blk_full {
  border: 1px solid var(--honey);
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: 20px;
}

.second_flex_blk_full h6 {
  margin-bottom: 0;
  text-transform: uppercase;
  color: var(--honey);
}

.partner_blk_sec {
  padding-top: 0;
  margin-top: -20px;
}

.partner_blk_sec .sec_heading h2 {
  font-size: 30px;
  text-transform: uppercase;
  opacity: 0.5;
  font-family: "Bold";
  text-align: center;
}

.partner_blk_sec .image {
  margin-top: 40px;
}

/* =============training_blk============== */

.training_blk .contain>.flex {
  margin: -10px;
}

.training_blk .col {
  width: calc(100% / 3);
  padding: 10px;
}

.training_blk .col .inner {
  position: relative;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid var(--honey);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.training_blk .col .inner a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.training_blk .col .inner .penal_heading {
  padding: 15px;
  height: 110px;
  background: #b5936e17;
}

.training_blk .col .inner .penal_heading h4 {
  font-size: 17px;
  color: var(--honey);
  margin-bottom: 5px;
}

.training_blk .col .inner .penal_heading p {
  font-size: 14px;
}

.training_blk .col .inner .panel_body {
  position: relative;
}

.training_blk .col .inner .panel_body .image {
  width: 100%;
  padding-bottom: 30%;
  overflow: hidden;
  position: relative;
}

.training_blk .col .inner .panel_body .image img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}

.training_blk .col .inner .panel_body .badge_training {
  position: absolute;
  top: 13px;
  right: 13px;
  z-index: 1;
  background: #b5936e;
  color: #fff;
  padding: 3px 20px;
  border-radius: 50px;
  font-size: 13px;
}

.training_blk .col .inner .panel_footer {
  padding: 15px;
}

.training_blk .col .inner .panel_footer .summery {
  min-height: 80px;
  margin-bottom: 10px;
  text-align: left;
}

.training_blk .col .inner .panel_footer .summery p {
  font-size: 14px;
  line-height: 1.4;
}

.training_blk .col .inner .panel_footer>.flex {
  gap: 10px;
}

.training_blk .col .inner .panel_footer .listing_public {
  /* width: 60%; */
  flex: 1.5;
}

.training_blk .col .inner .panel_footer .listing_private {
  /* width: 40%; */
  flex: 1;
}

.training_blk .col .inner .panel_footer ._innerlst {
  border: 1px solid #ddd;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  overflow: hidden;
}

.training_blk .col .inner .panel_footer ._innerlst .panel-heading {
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-size: 12px;
  padding: 2px 15px;
}

.training_blk .col .inner .panel_footer .listing_private ._innerlst .panel-heading {
  background-color: var(--honey);
}

.training_blk .col .inner .panel_footer .listing_public ._innerlst .panel-heading {
  background-color: var(--black);
}

.training_blk .col .inner .panel_footer ._innerlst .flex {
  gap: 3px;
  padding: 5px;
}

.training_blk .col .inner .panel_footer ._innerlst .flex>div {
  flex: 1;
  align-self: center;
}

.training_blk .col .inner .panel_footer ._innerlst .flex>div:nth-child(2) {
  flex: 1.5;
}

.rolodex-widget {
  padding-top: 4px;
}

.rolodex-widget>.rings {
  border: 2px solid #ccc;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: white;
  position: relative;
  height: 15px;
}

.rolodex-widget>.rings>.left-ring,
.rolodex-widget>.rings>.right-ring {
  position: absolute;
  top: -5px;
  width: 13px;
  height: 10px;
}

.rolodex-widget>.rings>.left-ring {
  left: 0px;
  border-right: 2px solid #ccc;
}

.rolodex-widget>.rings>.right-ring {
  right: 0px;
  border-left: 2px solid #ccc;
}

.rolodex-widget>.sheet {
  border: 2px solid #ccc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: white;
  padding: 5px 4px;
  min-height: 43px;
  font-size: 12px;
}

.rolodex-widget>.sheet span {
  display: block;
}

.training_blk .col .inner .panel_footer ._innerlst .flex .duration ul li {
  display: block;
  font-size: 10px;
  line-height: 1.3;
}

.training_blk .col .inner .panel_footer ._innerlst .flex .duration ul li strong {
  display: block;
}

.training_blk .col .inner .panel_footer ._innerlst .flex>div._price {
  font-size: 17px;
  color: var(--honey);
}

.training_blk .col .inner .panel_footer .listing_private ._innerlst {
  height: 100%;
}

.training_blk .col .inner .panel_footer .listing_private ._innerlst p {
  padding: 5px 5px;
  font-size: 12px;
  line-height: 1.4;
  height: 81px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlight_blk_gray {
  border: 1px solid #ddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 20px;
  margin-top: 30px;
}

.employer_grid_home {
  padding-top: 60px !important;
  padding-bottom: 0 !important;
  margin-bottom: -30px;
}

.employer_grid_home .col .inner {
  height: 400px;
}

.employer_grid_home .col .inner .image {
  top: 0;
}

.employer_grid_home .col .inner .image img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.employer_grid_home .col .inner .cntnt {
  position: relative;
  z-index: 1;
  background: #b5936edc;
  top: 20px;
  left: 20px;
  color: #fff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.employer_grid_home .col .inner .cntnt h2 {
  color: #fff;
}

.filter_show_cell {
  display: none;
}

/*_____ upperlay _____*/

.upperlay {
  position: fixed;
  top: 0;
  /* left: 280px; */
  right: 0;
  bottom: 0;
  background: rgb(10 33 48 / 0.9);
  opacity: 0;
  visibility: hidden;
  backdrop-filter: blur(5px);
  transition: all ease 0.5s;
  z-index: 100;
}

.upperlay.active {
  opacity: 1;
  visibility: visible;
}

#pageloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5f6f8;
  z-index: 999999;
}

.loader {
  width: 40px;
  height: 40px;
  border: 4px solid #b5936e;
  border-right: 4px solid #f0f5f9;
  border-radius: 75%;
  animation: spin 0.5s linear infinite;
}

.validation-error {
  color: red;
  font-size: 15px;
}

.input-error {
  border: 1px solid red;
}

.spinnerHidden {
  display: none !important;
}

/* ==========featured_lbl====== */
.featured_lbl {
  background: #b5936e;
  width: fit-content;
  font-size: 1.3rem;
  padding: 2px 10px;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  color: #fff;
}

.absolute_action.dash_actions .dropCnt {
  left: -165px;
  z-index: 1;
  margin-top: -34px;
}

.absolute_action {
  position: absolute;
}

.absolute_action:hover .dropCnt {
  visibility: visible;
  opacity: 1;
}

/* =========applicant_lst_flex======= */
.applicant_lst_flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.applicant_lst_flex ul {
  display: flex;
  flex-wrap: nowrap;
  align-self: center;
}

.applicant_lst_flex a {
  align-self: center;
  color: var(--honey);
  font-size: 1.3rem;
}

.view_all {
  font-size: 1.3rem;
  color: var(--honey);
  font-weight: 500;
}

.applicant_lst_flex ul li {
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border: 1px solid #fff;
}

.applicant_lst_flex ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.applicant_lst_flex ul li:not(:first-child) {
  margin-left: -1.5rem;
}

.act_dash_lnk_new h5 {
  font-size: 17px;
  font-family: "Bold";
  color: #000;
}

.act_dash_lnk_new .cv_lnk {
  align-self: center;
  font-size: 1.3rem;
  color: var(--honey);
  text-decoration: underline;
  cursor: pointer;
}

.candidate_flex_lst.dash_board_candidates .col {
  width: 100%;
}

.chat_abt_blk {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 4rem;
  height: 4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background-color: var(--honey);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat_abt_blk img {
  width: 2rem;
  filter: brightness(0) invert(1);
}

.popup.push_popup.active {
  display: block;
}

.popup.push_popup p strong {
  color: var(--honey);
}

.attachments_sec {
  display: flex;
}

.attachments {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.attachments .attachment {
  width: 100px;
  height: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f6f8;
  position: relative;
  border: 1px solid #000;
  height: 40px;
}

.attachments .attachment .remove {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 5px 0 5px;
  cursor: pointer;
  -webkit-border-radius: 0 5px 0 5px;
  -moz-border-radius: 0 5px 0 5px;
  -ms-border-radius: 0 5px 0 5px;
  -o-border-radius: 0 5px 0 5px;
}

.attachments .attachment span {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

/* ==========payment_tbl======== */
.payment_tbl .dash_actions a:nth-child(1) {
  margin-right: 5px;
}

/* =========carousel======= */
.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.cVXxbE {
  box-shadow: 0 0 1px 3px rgb(216 205 108) !important;
  background: #c5a470 !important;
  outline: 0;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }

  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}

/* =========Error Page======= */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.error-image {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
}

.error-code {
  font-size: 10rem;
  color: #b3b3b3;
  margin-bottom: 1rem;
}

.error-text {
  font-size: 1.5rem;
  color: #b3b3b3;
}

.error-back-button {
  padding: 0.5rem 1rem;
  background-color: #b3b3b3;
  color: #fff;
  border-radius: 5px;
  border: none;
  font-size: 1.2rem;
  margin-top: 1rem;
  cursor: pointer;
}

/* =========Tags======= */
.tags .rti--container {
  padding-top: 20px !important;
}

/* =========Resume======= */
.download_resume {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
}

.remove_text_btn {
  font-size: 14px;
  cursor: pointer;
}

/* =========active inactive button======= */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  /* background-color: white; */
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.job_results .colR .pagination>li.active>a {
  background-color: #b5936e;
  border-color: #b5936e;
}

.cv_pdf {
  padding-left: 10px;
  padding-bottom: 5px;
  padding-right: 10px;
}

.cv_pdf_inner {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  font-family: Arial;
  font-size: 14px;
  color: #333;
  line-height: 25px;
}
.cv_pdf_inner >table{
  color: #333; font-size: 14px; font-family: 'Jost', sans-serif; line-height: 1.3; margin: auto; border-collapse: collapse;
}
.cv_name_td{
  font-family: 'Jost', sans-serif;
    font-weight: 600;
    font-size: 42px;
    color: #080808;
}
.cv_email_td{
  font-size: 16px;
    color: #414141;
}
.education_table{
  background: #fff;
    color: #333;
    border-collapse: collapse;
}
.education_table_td{
  font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}
.exp_table{
  background: #fff; color: #333; border-collapse: collapse;
}
.exp_table_td{
  font-size: 20px;font-weight: 600;text-transform: uppercase;
}
.lang_td{
  font-size: 20px;font-weight: 600;text-transform: uppercase;
}
.skills_td{
  font-size: 16px;font-weight: 600;color: #5f5f5f;
}