.container{
    position: relative;
    width: 100%;
    margin: 3rem 0 4rem;
  }
  .slider {
      position: relative;
      width: 100%;
      /* margin-left: -1rem; */
    }
    
    .slider__track,
    .slider__range,
    .slider__left-value,
    .slider__right-value {
      position: absolute;
    }
    
    .slider__track,
    .slider__range {
      border-radius: 3px;
      height: 5px;
    }
    
    .slider__track {
      background-color: #b5936e;
      width: 100%;
      z-index: 1;
    }
    
    .slider__range {
      background-color: #b5936e;
      z-index: 2;
    }
    
    .slider__left-value,
    .slider__right-value {
      color: #dee2e6;
      font-size: 12px;
      margin-top: 18px;
    }
    
    .slider__left-value {
      left: 3px;
      color: #061237;
      font-size: 1.5rem;
    }
    
    .slider__right-value {
      right: -4px;
      color: #061237;
      font-size: 1.5rem;
    }
    
    /* Removing the default appearance */
    .thumb,
    .thumb::-webkit-slider-thumb {
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
    }
    
    .thumb {
      pointer-events: none;
      position: absolute;
      height: 0;
      width: 96%;
      outline: none;
      left: 0px;
    }
    
    .thumb--zindex-3 {
      z-index: 3;
    }
    
    .thumb--zindex-4 {
      z-index: 4;
    }
    
    .thumb--zindex-5 {
      z-index: 5;
    }
    
    /* For Chrome browsers */
    .thumb::-webkit-slider-thumb {
      background-color: #f4f1eb;
      border: none;
      border-radius: 50%;
      /* box-shadow: 0 0 1px 1px #ced4da; */
      border: 3px solid #b5936e;
      cursor: pointer;
      height: 18px;
      width: 18px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }
    
    /* For Firefox browsers */
    .thumb::-moz-range-thumb {
      background-color: #f4f1eb;
      border: none;
      border-radius: 50%;
      border: 3px solid #b5936e;
      /* box-shadow: 0 0 1px 1px #ced4da; */
      cursor: pointer;
      height: 18px;
      width: 18px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }
    